<template>
    <ul v-if="company" class="specs-type-1 firmology">
        <li v-if="company.industry">
            <div class="title">
                {{ $t('common.industry')}}
            </div>
            <div class="content">
                {{ company.industry }}
            </div>
        </li>
        <li v-if="company.employees_range && company.employees_range != '-1'">
            <div class="title">
                {{ $t('common.employees_range')}}
            </div>
            <div class="content">
                {{ company.employees_range }}
            </div>
        </li>
        <li v-if="company.revenue_range && company.revenue_range != '-1'">
            <div class="title">
                {{ $t('common.revenue_range')}}
            </div>
            <div class="content">
                {{ company.revenue_range }}
            </div>
        </li>
        <li v-if="company.orgtype">
            <div class="title">
                {{ $t('common.orgtype')}}
            </div>
            <div class="content">
                {{ company.orgtype }}
            </div>
        </li>
        <li v-if="company.coc">
            <div class="title">
                {{ $t('common.coc')}}
            </div>
            <div class="content">
                {{ company.id }}
            </div>
        </li>
        <li v-if="company.vat">
            <div class="title">
                {{ $t('common.vat')}}
            </div>
            <div class="content">
                {{ company.vat }}
                <div v-if="vatStatus !== null" :class="['indicator-type-1', vatStatus === 1 ? 'valid' : vatStatus === 0 ? 'invalid' : '']"></div>
            </div>
        </li>
    </ul>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { fetchUrl } from '@/services/ppApi';

    export default {
        name: "ProspectContactInformation",
        data() {
            return {
                vatStatus: null
            }
        },
        computed: {
            company() {
                return useProspectStore().prospect;
            },
            vat() {
                return useProspectStore().vat;
            },
            showVatVerification() {
                return !!this.vat && !!Object.keys(this.vat).length > 0 && this.vat.api_urls?.length > 0;
            }
        },
        methods: {
            async verifyVat() {
                if (!this.vat || this.vat.api_urls?.length < 1) { return; }
                try {
                    const response = await fetchUrl(this.vat.api_urls[0]);
                    if(response.data.vat_detail?.active === 1) {
                        this.vatStatus = response.data.vat_detail.active;
                    } else if (response.data.vat_detail && Object.keys(response.data.vat_detail).length < 1) {
                        this.vatStatus = 0;
                    }
                } catch {
                    this.vatStatus = null;
                }
            }
        },
        mounted() {
            this.verifyVat();
        }
    }
</script>