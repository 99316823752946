<template>
	<div class="password-select-view">
		<AuthSection>
			<router-link class="logo-link" :to="{ name: 'home' }">
				<div class="logo-pos"></div>
			</router-link>
			<h1 class="h4">{{ $t(title) }}</h1>
			<p class="description">{{ $t(description) }}</p>
			<PasswordSelectForm />
			<p class="notice">
				{{ $t(reset_expired_notice) }}
				<router-link class="link" :to="{ name: 'passwordReset' }">{{ $t(reset_expired_link_text) }}</router-link>.
			</p>
		</AuthSection>
	</div>
</template>

<script>
	import i18nViewMixin from '@/mixins/i18nViewMixin';
	import AuthSection from '@/components/sections/AuthSection';
	import PasswordSelectForm from '@/components/forms/PasswordSelectForm.vue';

	export default {
		name: 'PasswordSelectView',
		mixins: [i18nViewMixin],
		components: {
			AuthSection,
			PasswordSelectForm
		},
		data() {
			return {
				seo_title: 'views.password_select.seo_title',
				title: 'views.password_select.title',
				description: 'views.password_select.description',
				reset_expired_notice: 'notices.reset_expired.notice',
				reset_expired_link_text: 'notices.reset_expired.link_text',
			};
		}
	}
</script>