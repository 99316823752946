import { createI18n } from 'vue-i18n';

export const availableLocales = [
  { code: 'en', label: 'English', icon: 'flag-gb' },
  { code: 'nl', label: 'Nederlands', icon: 'flag-nl', }
];
export const defaultLocale = 'nl';

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages: {},
});

export async function loadLocaleMessages(locale) {
  if (Object.keys(i18n.global.getLocaleMessage(locale)).length > 0) {
    return locale;
  }

  try {
    const messages = await import(`../locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
    return locale;
  } catch (error) {
    await loadLocaleMessages(defaultLocale);
    return defaultLocale;
  }
}

export function setI18nLanguage(locale) {
  i18n.global.locale = locale;
  document.querySelector('html').setAttribute('lang', locale);
}

export default i18n;