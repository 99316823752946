import { mapState, mapActions } from 'pinia';
import { useAccountStore } from '@/stores/account';

export default {
    computed: {
        ...mapState(useAccountStore, {
            account: 'account',
            accountLoading: 'loading',
            accountError: 'error',
        }),
        accountStore() {
            return useAccountStore();
        },
        loading() {
            return this.accountLoading;
        },
        error() {
            return this.accountError;
        }
    },
    methods: {
        ...mapActions(useAccountStore, ['getAccount']),
        async fetchAccount() {
            await this.getAccount();
        }
    },
    async created() {
        await this.fetchAccount();
    },
    unmounted() {
        this.accountStore.resetState();
    }
};
