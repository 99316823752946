<template>
    <OptionsPanel ref="optionsPanel">
        <template #options-results v-if="total || total === 0">
            {{ 
                total === 1 ?
                    $t('views.prospects.results_count.s', { found: total }) :
                    $t('views.prospects.results_count.p', { found: total })
            }}
        </template>

        <template #options-default>
            <div class="form-type-1">
                <CustomInput
                    v-model="options.search"
                    inputKey="prospects_search"
                    @update:modelValue="onChangedDebounced"
                />
                <CustomSelect
                    v-model="options.sort"
                    inputKey="prospects_sort"
                    @update:modelValue="onChanged"
                    :options="sortOptions"
                />
                <CustomSelect
                    v-model="options.label"
                    inputKey="prospects_filter_label"
                    @update:modelValue="onChanged"
                    :options="filterLabelOptions"
                />
            </div>
        </template>

        <template #options-full>
            <div class="form-type-1">
                <CustomCheckboxTagsFilter
                    v-model="options.tags"
                    @update:modelValue="onChanged"
                />
                <CustomSelectTimePeriod
                    v-model="options.created"
                    @update:modelValue="onChanged"
                    inputKey="prospects_filter_created"
                />
                <CustomSelectTimePeriod
                    v-model="options.updated"
                    @update:modelValue="onChanged"
                    inputKey="prospects_filter_updated"
                />
                <CustomRadio
                    v-model="options.contacts"
                    inputKey="prospects_filter_contacts"
                    :options="contactsOptions"
                    @update:modelValue="onChanged"
                    cssClass="custom-radio-type-2"
                />
                <CustomRadio
                    v-model="options.visits"
                    inputKey="prospects_filter_visits"
                    :options="visitsOptions"
                    @update:modelValue="onChanged"
                    cssClass="custom-radio-type-2"
                />
                <CustomSelectOwner
                    v-model="options.owner"
                    @update:modelValue="onChanged"
                />
            </div>
        </template>

        <template #actions>
            <div :class="hasDefaultOptions ? 'btn-disabled' : 'btn-6'" @click="onReset()">
                <div class="icon-reset-neg"></div>
                <span>{{ $t('common.reset_1') }}</span>
            </div>
            <div class="btn-2" @click="onDownload()">
                <div class="icon-download-neg"></div>
                <span>{{ $t('common.download') }}</span>
            </div>
        </template>
    </OptionsPanel>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { useProspectsStore } from '@/stores/prospects';
    import { useScreenWidthStore } from '@/stores/utils/screenWidth';
    import { mapState, mapActions, mapGetters } from 'pinia';
    import OptionsPanel from '@/components/panels/options/OptionsPanel';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CustomSelect from '@/components/elements/inputs/CustomSelect';
    import CustomRadio from '@/components/elements/inputs/CustomRadio';
    import CustomCheckboxTagsFilter from '@/components/elements/inputs/CustomCheckboxTagsFilter';
    import CustomSelectOwner from '@/components/elements/inputs/CustomSelectOwner';
    import CustomSelectTimePeriod from '@/components/elements/inputs/CustomSelectTimePeriod';

    export default {
        name: "ProspectsOptions",
        components: {
            OptionsPanel,
            CustomInput,
            CustomSelect,
            CustomRadio,
            CustomCheckboxTagsFilter,
            CustomSelectOwner,
            CustomSelectTimePeriod
        },
        data() {
            return {
                sortOptions: [
                    { value: 'default', text: 'fields.prospects_sort.option_default' },
                    { value: 'company_name-asc', text: 'fields.prospects_sort.option_name_asc' },
                    { value: 'company_name-desc', text: 'fields.prospects_sort.option_name_desc' },
                    { value: 'changed_time-asc', text: 'fields.prospects_sort.option_changed_date_asc' },
                    { value: 'changed_time-desc', text: 'fields.prospects_sort.option_changed_date_desc' },
                    { value: 'inserted_date-asc', text: 'fields.prospects_sort.option_creation_date_asc' },
                    { value: 'inserted_date-desc', text: 'fields.prospects_sort.option_creation_date_desc' },
                ],
                filterLabelOptions: [
                    { value: 'all', text: 'fields.prospects_filter_label.option_all' },
                    { value: '1', text: 'fields.prospects_filter_label.option_1' },
                    { value: '0', text: 'fields.prospects_filter_label.option_0' },
                    { value: '-1', text: 'fields.prospects_filter_label.option_-1' },
                ],
                contactsOptions: [
                    { value: 'all', text: 'fields.prospects_filter_contacts.option_all' },
                    { value: '1', text: 'fields.prospects_filter_contacts.option_1' },
                    { value: '0:0', text: 'fields.prospects_filter_contacts.option_0' },
                ],
                visitsOptions: [
                    { value: 'all', text: 'fields.prospects_filter_visits.option_all' },
                    { value: '1', text: 'fields.prospects_filter_visits.option_1' },
                    { value: '0:0', text: 'fields.prospects_filter_visits.option_0' },
                ],
                miscOptions: [
                    { value: 'visits', text: 'fields.prospects_filter_misc.has_visits' },
                    { value: 'contacts', text: 'fields.prospects_filter_misc.has_contacts' }
                ]
            }
        },
        computed: {
            ...mapState(useProspectsStore, ['options', 'total']),
            ...mapGetters(useProspectsStore, ['hasDefaultOptions']),
            screenWidthStore() {
                return useScreenWidthStore();
            },
            hasLargeScreen() {
                return this.screenWidthStore.width >= 992;
            },
        },
        methods: {
            ...mapActions(useProspectsStore, ['updateOptions', 'setUpdatingOptions', 'resetOptions']),
            onChanged() {
                this.updateOptions(this.options);
            },
            onChangedDebounced(value) {
                this.setUpdatingOptions(true);
                this.options.search = value;
                this.updateDebounced();
            },
            updateDebounced: debounce(function() {
                this.updateOptions(this.options);
            }, 500),
            onReset() {
                this.resetOptions();
                this.$refs.optionsPanel.toggleOptions(false);
                this.$refs.optionsPanel.toggleFullOptions(false);
            },
            async onDownload() {
                alert('Download');
            }
        }
    }
</script>