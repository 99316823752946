export function dateOptionToEpoch(option) {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const today = new Date();

    if(option === 'all') {
        return null;
    } else if (option.startsWith('d_')) {
        const daysAgo = parseInt(option.split('_')[1], 10);
        const date = new Date(today);
        date.setDate(today.getDate() - daysAgo);
        return Math.floor(date.getTime() / 1000);
    } else if (option === 'y_current') {
        const startOfYear = new Date(currentYear, 0, 1);
        return Math.floor(startOfYear.getTime() / 1000);
    } else if (option === 'y_last') {
        const startOfLastYear = new Date(lastYear, 0, 1);
        const endOfLastYear = new Date(lastYear, 11, 31, 23, 59, 59);
        return `${Math.floor(startOfLastYear.getTime() / 1000)}:${Math.floor(endOfLastYear.getTime() / 1000)}`;
    } else {
        return option;
    }
}