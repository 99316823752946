<template>
    <div :class="['custom-checkboxes', cssClass]">
        <label v-if="showLabel && $te(label)" :for="uniqueId" class="input-label">{{ $t(label) }}</label>
        <ul>
            <li v-for="option in options" :key="option.value" :class="['custom-checkbox', { required: option.required }]">
                <input
                    type="checkbox"
                    :id="`${uniqueId}-${option.value}`"
                    :name="inputKey"
                    :value="option.value"
                    :checked="isChecked(option.value)"
                    :required="option.required"
                    @change="onInput"
                />
                <label :for="`${uniqueId}-${option.value}`" :class="['option-label', isChecked(option.value) ? 'selected' : 'not-selected']">
                    <div class="custom-checkbox-icon"></div>
                    <div v-if="option.icon && !option.icon.includes('http')" :class="['option-icon', `icon-${option.icon}-pos`]"></div>
                    <img v-if="option.icon && option.icon.includes('http')" class="option-icon" :src="option.icon" />
                    <div v-if="option.noTranslate" class="custom-checkbox-text" v-html="option.text"></div>
                    <div v-else class="custom-checkbox-text" v-html="$t(option.text)"></div>
                </label>
            </li>
        </ul>
        <p v-if="inputError" class="input-error">{{ $te(error) ? $t(error) : $t('fields.default.checkbox.error') }}</p>
    </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
    props: {
        inputKey: String,
        modelValue: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true
        },
        cssClass: {
            type: String,
            required: false
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            inputError: false,
            label: `fields.${this.inputKey}.label`,
            error: `fields.${this.inputKey}.error`,
            uniqueId: `${this.inputKey}-${Math.random().toString(36).substr(2, 9)}`
        };
    },
    methods: {
        isChecked(value) {
            return this.modelValue.includes(value);
        },
        onInput(event) {
            const value = event.target.value;
            let newValue = [...this.modelValue];
            if (event.target.checked) {
                if (!newValue.includes(value)) {
                    newValue.push(value);
                }
            } else {
                newValue = newValue.filter(item => item !== value);
            }
            this.$emit('update:modelValue', newValue);
            nextTick(() => {
                this.validateInput();
            });
        },
        validateInput() {
            const allValid = this.options.every(option => {
                if (option.required) {
                    return this.modelValue.includes(option.value);
                }
                return true;
            });

            this.inputError = (this.required && this.modelValue.length === 0) || !allValid;

            this.options.forEach(option => {
                const input = document.getElementById(`${this.uniqueId}-${option.value}`);
                if (input) {
                    if (option.required && !this.modelValue.includes(option.value)) {
                        input.classList.add('is-invalid');
                        input.classList.remove('is-valid');
                    } else {
                        input.classList.add('is-valid');
                        input.classList.remove('is-invalid');
                    }
                }
            });

            return !this.inputError;
        },
        triggerValidation() {
            return this.validateInput();
        }
    },
};
</script>
