<template>
	<div class="login-view">
		<AuthSection>
			<router-link class="logo-link" :to="{ name: 'home' }">
				<div class="logo-pos"></div>
			</router-link>
			<h1 class="h4">{{ $t(title) }}</h1>
			<p class="description">{{ $t(description) }}</p>
			<LoginForm />
			<p class="notice">
				{{ $t(forgot_password_notice) }}
				<router-link class="link" :to="{ name: 'passwordReset' }">{{ $t(forgot_password_link_text) }}</router-link>.
			</p>
			<p class="notice">
				{{ $t(no_account_notice) }}
				<router-link class="link" :to="{ name: 'register' }">{{ $t(no_account_link_text) }}</router-link>.
			</p>
		</AuthSection>
	</div>
</template>

<script>
	import i18nViewMixin from '@/mixins/i18nViewMixin';
	import AuthSection from '@/components/sections/AuthSection';
	import LoginForm from '@/components/forms/LoginForm.vue';

	export default {
		name: 'LoginView',
		mixins: [i18nViewMixin],
		components: {
			AuthSection,
			LoginForm
		},
		data() {
			return {
				seo_title: 'views.login.seo_title',
				title: 'views.login.title',
				description: 'views.login.description',
				forgot_password_notice: 'notices.forgot_password.notice',
				forgot_password_link_text: 'notices.forgot_password.link_text',
				no_account_notice: 'notices.no_account.notice',
				no_account_link_text: 'notices.no_account.link_text'
			};
		},
	}
</script>