<template>
    <component v-if="mainNav.showNav" :is="currentNavType"></component>
</template>

<script>
    import { useMainNavStore } from '@/stores/mainNav';
    import { useScreenWidthStore } from '@/stores/utils/screenWidth';
    import { useUserStore } from '@/stores/user';
    import { useAccountStore } from '@/stores/account';
    import TopNav from '@/components/navigation/TopNav';
    import TopNavAccounts from '@/components/navigation/TopNavAccounts';
    import SideNav from '@/components/navigation/SideNav';

    export default {
        name: 'MainNav',
        components: {
            TopNav,
            TopNavAccounts,
            SideNav
        },
        computed: {
            mainNav() {
                return useMainNavStore();
            },
            screenWidthStore() {
                return useScreenWidthStore();
            },
            userStore() {
                return useUserStore();
            },
            accountStore() {
                return useAccountStore();
            },
            currentNavType() {
                if (this.accountStore.hasAccountId) {
                    if(this.screenWidthStore.width >= 769) {
                        return 'SideNav';
                    } else {
                        return 'TopNav';
                    }
                } else {
                    return 'TopNavAccounts';
                }
            }
        },
        methods: {
            toggleNav() {
                !this.userStore.isAuthenticated ? this.mainNav.toggleNav(false) : this.mainNav.toggleNav(true);
            }
        },
        watch: {
            $route() {
                this.toggleNav();
            }
        },
        created() {
            this.toggleNav();
        }
    }
</script>
