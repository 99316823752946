<template>
    <template v-if="!loading && !error && found > 0">
        <div class="container-fluid item-lists-type-1">
            <div class="container">
                <AccountsListItem
                v-for="account in displayedAccounts"
                :key="account.account_id"
                :account="account"
            />
            </div>
        </div>
        <PaginationPanel
            :pagination="pagination"
            :total="total"
            @update-pagination="handlePaginationUpdate"
        />
    </template>

    <NoResultsSection
        v-else-if="!loading && !error"
        :message="options.search ? 'views.accounts.no_results_search' : ''"
        :translationPlaceholders="options.search ? { query: `<span>${options.search}</span>` } : {}"
    />
    <ErrorSection v-else-if="error" :error="error" />
    <LoadingSection v-else />
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useAccountsStore } from '@/stores/accounts';
    import AccountsListItem from '@/components/accounts/AccountsListItem.vue';
    import ErrorSection from '@/components/sections/ErrorSection';
    import LoadingSection from '@/components/sections/LoadingSection';
    import NoResultsSection from '@/components/sections/NoResultsSection';
    import PaginationPanel from '@/components/panels/pagination/PaginationPanel';
    import { scrollToTop } from '@/utils/scroll';

    export default {
        name: 'AccountsList',
        components: {
            AccountsListItem,
            ErrorSection,
            LoadingSection,
            NoResultsSection,
            PaginationPanel
        },
        data() {
            return {
                displayedAccounts: []
            };
        },
        computed: {
            ...mapState(useAccountsStore, ['accounts', 'loading', 'error', 'found', 'options', 'pagination', 'total']),
        },
        methods: {
            ...mapActions(useAccountsStore, ['loadAccounts', 'updatePagination']),
            handlePaginationUpdate(newPagination) {
                this.updatePagination(newPagination);
            }
        },
        created() {
            this.loadAccounts();
        },
        watch: {
            accounts(newAccounts) {
                scrollToTop();
                this.displayedAccounts = [];
                newAccounts.forEach((account, index) => {
                    setTimeout(() => {
                        this.displayedAccounts.push(account);
                    }, index * 50 < 500 ? index * 50 : 500);
                });
            }
        },
    };
</script>
