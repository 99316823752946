<template>
    <WidgetSection :loading="loading" loadingText="components.prospect_contacts.loading_text">
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_contacts.name')}}
            </div>
        </template>

        <template v-slot:section-actions>
            <div class="btn-1 btn-xs-icon" @click="addContactPopup">
                <div class="icon-plus-neg"></div>
            </div>
        </template>

        <template v-if="hide" v-slot:section-noresults >
            <div @click="addContactPopup" class="btn-1 btn-s-icon">
                <div class="icon-plus-neg"></div>
                <span>{{ $t('components.create_contact.button_add')}}</span>
            </div>
        </template>

        <template v-else v-slot:section-content>
            <div class="row row-content">
                <div class="table-container">
                    <table class="table-type-1 companies">
                        <thead>
                            <tr class="table-header">
                                <td></td>
                                <td>{{ $t('common.name') }}</td>
                                <td>{{ $t('common.job_title') }}</td>
                                <td>{{ $t('common.email') }}</td>
                                <td>{{ $t('common.phone') }}</td>
                                <td>{{ $t('common.last_modified') }}</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="company" v-for="person in contacts" :key="person.contact_id">
                                <td class="image">
                                    <ContactImage 
                                        :imgUrl="person.image"
                                        :name_first="person.name_first"
                                        :last_first="person.last_first"
                                    />
                                </td>
                                <td class="company-name">{{ person.name_first }} {{ person.name_last }}</td>
                                <td>{{ person.job_title }}</td>
                                <td>{{ person.email }}</td>
                                <td>{{ person.phone }}</td>
                                <td>{{ epochToDisplayDate(person.changed_time) }}</td>
                                <td class="actions">
                                    <div>
                                        <div class="btn-1 btn-xs-icon" @click="editContactPopup(person)">
                                            <div class="icon-edit-neg"></div>
                                        </div>
                                        <div class="btn-6 btn-xs-icon" @click="deleteContactPopup(person)">
                                            <div class="icon-trash-neg"></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';

    import i18n from "@/plugins/i18n";
    import { usePopupStore } from '@/stores/popup';
    import { useToastStore } from '@/stores/toast';
    import { markRaw } from 'vue';
    import ContactImage from '@/components/elements/misc/ContactImage';
    import EditContactForm from '@/components/forms/EditContactForm';
    import AddContactForm from '@/components/forms/AddContactForm';

    export default {
        name: "ProspectContacts",
        components: {
            WidgetSection,
            ContactImage
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {
            hide() {
                return !this.loading && this.contacts.length < 1;
            },
            contacts() {
                return useProspectStore().contacts;
            },
        },
        methods: {
            async fetchContacts() {
                this.loading = true;
                await useProspectStore().fetchContacts();
                this.loading = false;
            },
            editContactPopup(contact) {
                usePopupStore().showPopup('form', {
                    title: 'popups.edit_contact.title',
                    description: 'popups.edit_contact.description',
                    formComponent: markRaw(EditContactForm),
                    formProps: {
                        contact: contact
                    },
                    onFormSuccess: () => {
                        useToastStore().addToast(i18n.global.t('toasts.success.contact_updated'), 'success');
                    }
                });
            },
            deleteContactPopup(contact) {
                usePopupStore().showPopup('confirm', {
                    title: 'popups.confirm_contact_delete.title',
                    description: 'popups.confirm_contact_delete.description',
                    btnConfirm: "popups.confirm_contact_delete.button_confirm",
                    btnCancel: "common.cancel_1",
                    danger: true,
                    translationPlaceholders: {
                        description: {
                            name: contact.name_first && contact.name_last ? contact.name_first + ' ' + contact.name_last : contact.name_first ? contact.name_first : contact.name_last ? contact.name_last : 'dit contact',
                        }
                    },
                    confirmFunction: () => this.deleteContact(contact)
                });
            },
            async deleteContact(contact) {
                try {
                    const success = await useProspectStore().deleteContact({
                        ...contact
                    });

                    if(success) {
                        useToastStore().addToast(i18n.global.t('toasts.success.contact_deleted'), 'success');
                    } else {
                        useToastStore().addToast(i18n.global.t('toasts.error.contact_deletion_failed'), 'danger');
                    }
                } catch {
                    useToastStore().addToast(i18n.global.t('toasts.error.contact_deletion_failed'), 'danger');
                }
            },
            // WARNING: The API doesn't support this yet! Check before release.
            addContactPopup() {
                usePopupStore().showPopup('form', {
                    title: 'popups.create_contact.title',
                    description: 'popups.create_contact.description',
                    formComponent: markRaw(AddContactForm),
                    onFormSuccess: () => {
                        useToastStore().addToast(i18n.global.t('toasts.success.contact_created'), 'success');
                    }
                });
            },
            epochToDisplayDate(epoch) {
                const date = new Date(epoch * 1000);
                const today = new Date();
                const isToday = today.getFullYear() === date.getFullYear() &&
                                today.getMonth() === date.getMonth() &&
                                today.getDate() === date.getDate();
                if (isToday) {
                    const timeOptions = { hour: 'numeric', minute: 'numeric' };
                    return date.toLocaleTimeString('nl-NL', timeOptions);
                } else {
                    const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
                    return date.toLocaleDateString('nl-NL', dateOptions);
                }
            }
        },
        mounted() {
            this.fetchContacts();
        }
    }
</script>