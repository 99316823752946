<template>
    <div :class="['container-fluid', 'options-type-1', cssClass]">
        <div class="container" ref="container">
            <div class="options-results" v-if="$slots['options-results'] && !hasLargeScreen" @click="toggle()">
                <slot name="options-results"></slot>
            </div>

            <div class="options">
                <div class="options-default">
                    <slot name="options-default"></slot>
                </div>
                <div class="options-full" v-if="hasFullOptions && !hasLargeScreen">
                    <slot name="options-full"></slot>
                </div>
            </div>

            <div class="actions">
                <div
                    @click="() => { toggleFullOptions() }"
                    v-if="hasFullOptions && hasLargeScreen"
                    :class="['toggle-options-full', hasLargeScreen ? 'btn-1' : 'btn-4']"
                >
                    <div :class="[!fullOptions ? 'icon-plus-neg' : 'icon-close-neg']"></div>
                    <span>{{ !fullOptions ? $t('components.prospect-options.expand') : $t('components.prospect-options.minimize') }}</span>
                </div>

                <slot name="actions"></slot>

                <div v-if="!hasLargeScreen" @click="toggle()" class="btn-1">
                    <div class="icon-close-neg"></div>
                    <span>{{ $t('common.close') }}</span>
                </div>
            </div>

            <div class="options-large" v-if="hasFullOptions && (fullOptions && hasLargeScreen)">
                <div class="options-full">
                    <slot name="options-full"></slot>
                </div>
                <div class="options-results" v-if="$slots['options-results']" @click="toggle()">
                    <slot name="options-results"></slot>
                </div>
            </div>
        </div>
    </div>

    <div class="options-panel-spacer"></div>

    <div @click="toggle()" v-if="hasLargeScreen && fullOptions || !hasLargeScreen" class="options-escape-type-1"></div>
</template>

<script>
    export default {
        name: "OptionsPanelContent",
        emits: ['toggle-close'],
        props: {
            hasLargeScreen: {
                type: Boolean,
                default: false
            },
            cssClass: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                fullOptions: false
            };
        },
        computed: {
            hasFullOptions() {
                return !!this.$slots['options-full'];
            }
        },
        methods: {
            toggleFullOptions(show = null) {
                this.fullOptions = show !== null ? show : !this.fullOptions;
            },
            toggle() {
                this.toggleFullOptions(false);
                this.$emit('toggle-close');
            },
            setContainerHeight() {
                const container = this.$refs.container;
                if (container) {
                    container.style.maxHeight = window.innerWidth >= 769 ? `${window.innerHeight - 0}px` : `${window.innerHeight - 64}px`;
                }
            }
        },
        mounted() {
            this.setContainerHeight();
            window.addEventListener('resize', this.setContainerHeight);
        },
        unmounted() {
            window.removeEventListener('resize', this.setContainerHeight);
        }
    }
</script>