<template>
  <div class="register-view">
    <AuthSection>
        <router-link class="logo-link" :to="{ name: 'home' }">
            <div class="logo-pos"></div>
        </router-link>
        <h1 class="h4">{{ $t(title) }}</h1>
        <p class="description">{{ $t(description) }}</p>
        <RegisterForm />
        <p class="notice">
            {{ $t(has_account_notice) }}
            <router-link class="link" :to="{ name: 'login' }">{{ $t(has_account_link_text) }}</router-link>.
        </p>
	</AuthSection>
  </div>
</template>

<script>
  import i18nViewMixin from '@/mixins/i18nViewMixin';
  import AuthSection from '@/components/sections/AuthSection';
  import RegisterForm from '@/components/forms/RegisterForm.vue';

  export default {
    name: 'RegisterView',
    mixins: [i18nViewMixin],
    components: {
      AuthSection,
      RegisterForm
    },
    data() {
			return {
        seo_title: 'views.register.seo_title',
				title: 'views.register.title',
				description: 'views.register.description',
				has_account_notice: 'notices.has_account.notice',
				has_account_link_text: 'notices.has_account.link_text'
			};
		}
  }
</script>