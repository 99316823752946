import { markRaw } from 'vue';
import i18n from "@/plugins/i18n";
import { usePopupStore } from '@/stores/popup';
import LoginForm from '@/components/forms/LoginForm';
import { useToastStore } from '@/stores/toast';

export function confirmAuthPopup() {
    const popupStore = usePopupStore();
    const toastStore = useToastStore();

    popupStore.showPopup('form', {
        title: 'popups.confirm_auth.title',
        description: 'popups.confirm_auth.description',
        formComponent: markRaw(LoginForm),
        formProps: {
            confirmAuth: true
        },
        onFormSuccess: () => {
            toastStore.addToast(i18n.global.t('toasts.success.user_identity_confirmed'), 'success');
        }
    });
}