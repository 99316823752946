<template>
    <div class="form-type-0">
        <form @submit.prevent="passwordSelect" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="password"
                inputKey="password_select"
                type="password"
                ref="password"
                autocomplete="new-password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}" 
                required
            />
            <CustomInput
                v-model="passwordConfirm"
                inputKey="password_confirm"
                type="password"
                ref="passwordConfirm"
                autocomplete="new-password"
            />
            <CustomInput
                v-model="r"
                inputKey="r"
                type="hidden"
            />

            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.password_select.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.password_select.error') }}</p>
        </form>
    </div>
</template>

<script>
    import router from '@/router';
    import { useUserStore } from '@/stores/user';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import formMixin from '@/mixins/formMixin';

    export default {
        mixins: [formMixin],
        components: {
            CustomInput
        },
        data() {
            return {
                password: '',
                passwordConfirm: '',
                r: ''
            };
        },
        computed: {
            userStore() {
                return useUserStore();
            }
        },
        methods: {
            async passwordSelect() {
                const form = this.$refs.form;
                if(form.checkValidity() && this.validateConfirmPassword()) {
                    this.loading = true;
                    const success = await this.userStore.passwordSelect(this.password, this.r);
                    this.loading = false;

                    if (success) {
                        form.reset();
                        router.push({ name: 'login' });
                        this.formError = false;

                    } else {
                        this.formError = true;
                    }
                } else if (!this.validateConfirmPassword()) {
                    this.validateAllFields();
                    this.$refs.passwordConfirm.inputError = true;
                } else {
                    this.validateAllFields();
                }
            },
            validateConfirmPassword() {
                return this.password === this.passwordConfirm;
            }
        },
        created() {
            this.r = this.$route.query.r || '';
            
            if(!this.r) {
                router.push({ name: 'resetPassword' });
            }
        },
    }
</script>
