<template>
  <div class="form-type-0 copy-paste-area">
    <textarea ref="textarea" :value="decodedInput" @click="copyToClipboard" readonly></textarea>
    <div v-if="!showCheck" @click="copyToClipboard" class="icon-copy-pos"></div>
    <div v-if="showCheck" class="icon-check-pos"></div>
  </div>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showCheck: false
    };
  },
  computed: {
      decodedInput() {
          return this.decodeHTMLEntities(this.code);
      }
  },
  methods: {
    copyToClipboard() {
      const textarea = this.$refs.textarea;
      textarea.select();
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      this.showCheck = true;
      setTimeout(() => {
        this.showCheck = false;
      }, 1500);
    },
    decodeHTMLEntities(encodedString) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = encodedString;
      return textArea.value;
    }
  }
};
</script>
