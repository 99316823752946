import router from '@/router';
import { defineStore } from 'pinia';
import { fetchProspects, fetchPeople, fetchContacts, addContact, editContact, deleteContact, fetchPageviews, updateProspect } from '@/services/ppApi';
import { useAccountStore } from '@/stores/account';

export const useProspectStore = defineStore('prospect', {
    state: () => ({
        prospect: null,
        people: [],
        contacts: [],
        pageviews: [],
        page_source: null, // TEMPORARY WORK AROUND AS API IS UNDER CONSTRUCTION
        loading: false,
        reviewsDetails: null,
        reviewsSummary: null,
        location: null,
        openingHours: null,
        searchOptions: null,
        profile: null,
        property: null,
        siterank: null,
        sitesearch: null,
        vacancies: null,
        news: null,
        vat: null,
        family: null,
        sameAddress: null
    }),
    actions: {
        async fetchProspect(id) {
            this.loading = true;

            try {
                const accountStore = useAccountStore();
                const response = await fetchProspects({
                    add_details: '*',
                    account_id: accountStore.account.account_id,
                    company: id
                });

                if(response.data.found && response.data.found > 0) {
                    this.setProspect(response.data.companies[0]);
                } else {
                    this.resetProspect();
                    router.push({ name: 'prospects' });
                }
                return true;
            } catch {
                router.push({ name: 'prospects' });
                return false;
            } finally {
                this.loading = false;
            }
        },
        async updateProspect(data, refresh = false) {
            const accountStore = useAccountStore();
            const prospect = {
                id: this.prospect.id,
                account_id: accountStore.account.account_id,
                ...data
            }
            try {
                const response = await updateProspect(prospect);
                if(response.data.found && response.data.found > 0) {
                    if(refresh) {
                        this.setProspect(response.data.companies[0]);
                        return true;
                    } else {
                        return response.data.companies[0];
                    }
                } else {
                    return false;
                }
            } catch {
                return false;
            }
        },
        async updateTags(tags) {
            try {
                const company = await this.updateProspect({tags});
                if(company) {
                    this.prospect.tags = company.tags || '';
                    return true;
                } else {
                    return false;
                }
            } catch {
                return false;
            }
        },
        async updateLabel(label) {
            try {
                const company = await this.updateProspect({label});
                if(company) {
                    this.prospect.label = company.label;
                    return true;
                } else {
                    return false;
                }
            } catch {
                return false;
            }
        },
        async fetchContacts() {
            if(!this.prospect || !this.prospect.id) { return }
            try {
                const accountStore = useAccountStore();
                const response = await fetchContacts({
                    account_id: accountStore.account.account_id,
                    company_id: this.prospect.id
                });
                if(response.data.found && response.data.found > 0) {
                    this.contacts = response.data.contacts;
                } else {
                    this.contacts = [];
                }
            } catch {
                this.contacts = [];
            }
        },
        async addContact(contact) {
            if(!this.prospect || !this.prospect.id) { return }
            try {
                const accountStore = useAccountStore();
                const response = await addContact({
                    account_id: accountStore.account.account_id,
                    company_id: this.prospect.id,
                    ...contact
                });
                if(response.data.found && response.data.found > 0) {
                    this.updateContactList(response.data.contacts[0]);
                    this.updatePeopleList(response.data.contacts[0], true);
                }
                return true;
            } catch {
                return false;
            }
        },
        async editContact(contact) {
            if(!this.prospect || !this.prospect.id) { return }
            try {
                const accountStore = useAccountStore();
                const response = await editContact({
                    account_id: accountStore.account.account_id,
                    company_id: this.prospect.id,
                    ...contact
                });
                if(response.data.found && response.data.found > 0) {
                    this.updateContactList(response.data.contacts[0]);
                }
                return true;
            } catch {
                return false;
            }
        },
        async deleteContact(contact) {
            if(!this.prospect || !this.prospect.id) { return }
            try {
                const accountStore = useAccountStore();
                const response = await deleteContact({
                    account_id: accountStore.account.account_id,
                    company_id: this.prospect.id,
                    ...contact
                });
                if(response.data.status && response.data.status === 'ok') {
                    this.updateContactList(contact, true);
                }
                return true;
            } catch {
                return false;
            }
        },
        updateContactList(contact, deleteContact = false) {
            const existingIndex = this.contacts.findIndex(c => c.contact_id === contact.contact_id);
            if (existingIndex !== -1) {
                if(deleteContact) {
                    this.contacts.splice(existingIndex, 1);
                } else {
                    this.contacts.splice(existingIndex, 1, contact);
                }
            } else {
                if(!deleteContact) {
                    this.contacts.push(contact);
                }
            }
        },
        async fetchPeople() {
            if(!this.prospect || !this.prospect.id) { return }
            try {
                const accountStore = useAccountStore();
                const response = await fetchPeople({
                    account_id: accountStore.account.account_id,
                    company_id: this.prospect.id,
                    limit: '100'
                });
                if(response.data.found && response.data.found > 0) {
                    this.people = response.data.people;
                } else {
                    this.people = [];
                }
            } catch {
                this.people = [];
            }
        },
        updatePeopleList(contact, deletePerson = false) {
            const existingIndex = this.people.findIndex(c => c.contact_id === contact.contact_id);
            if (existingIndex !== -1) {
                if(deletePerson) {
                    this.people.splice(existingIndex, 1);
                } else {
                    this.people.splice(existingIndex, 1, contact);
                }
            } else {
                if(!deletePerson) {
                    this.people.push(contact);
                }
            }
        },
        async fetchPageviews() {
            if(!this.prospect || !this.prospect.id) { return }
            try {
                const accountStore = useAccountStore();
                const response = await fetchPageviews({
                    account_id: accountStore.account.account_id,
                    id: this.prospect.id,
                    sources: 1
                });
                if(response.data.found && response.data.found > 0) {
                    this.pageviews = response.data.pageviews;
                } else {
                    this.pageviews = [];
                }

                if(response.data.page_sources && response.data.page_sources.length > 0) {
                    this.page_source = response.data.page_sources[0];
                }
            } catch {
                this.pageviews = [];
            }
        },
        setProspect(prospect) {
            const { details } = prospect || {};
            this.people = [];
            this.contacts = [];
            this.pageviews = [];
            this.page_source = null;
            this.reviewsDetails = details?.reviews?.reviews || null;
            this.reviewsSummary = details?.reviews?.summary || null;
            this.location = details?.map || null;
            this.openingHours = details?.open?.open_structured || null;
            this.searchOptions = details?.similar_search || null;
            this.profile = details?.profile || null;
            this.property = details?.property || null;
            this.siterank = details?.siterank || null;
            this.sitesearch = details?.sitesearch || null;
            this.vacancies = details?.vacancies || null;
            this.news = details?.news || null;
            this.vat = details?.vat || null;
            this.family = details?.family || null;
            this.sameAddress = details?.same_address || null;
            delete prospect.details;
            this.prospect = prospect;
        },
        resetProspect() {
            this.$reset();
        },
    }
});