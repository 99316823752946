<template>
    <img v-if="imageExists" :src="gravatarUrl" @error="onError" />
    <div v-else class="letter">{{ firstLetter }}</div>
</template>

<script>
import md5 from 'md5';

export default {
  name: 'GravatarImage',
  props: {
    email: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: Number,
      default: 128
    }
  },
  data() {
    return {
      imageExists: true
    };
  },
  computed: {
    gravatarUrl() {
      const hash = md5(this.email.trim().toLowerCase());
      return `https://www.gravatar.com/avatar/${hash}?s=${this.size}&d=404`;
    },
    firstLetter() {
        if(this.name && this.name != '') {
            return this.name.charAt(0).toUpperCase();
        } else {
            return this.email.charAt(0).toUpperCase();
        }
    }
  },
  methods: {
    onError() {
      this.imageExists = false;
    }
  }
};
</script>