<template>
    <ul class="specs-type-1 social-media" v-if="company" >
        <li class="buttons">
            <a v-if="company.linkedin_link" :href="company.linkedin_link" target="_blank" rel="noreferrer external">
                <div class="icon-linkedin-pos"></div>
            </a>
            <a v-if="company.facebook_link" :href="company.facebook_link" target="_blank" rel="noreferrer external">
                <div class="icon-facebook-pos"></div>
            </a>
            <a v-if="company.twitter_link" :href="company.twitter_link" target="_blank" rel="noreferrer external">
                <div class="icon-x-pos"></div>
            </a>
            <a v-if="company.instagram_link" :href="company.instagram_link" target="_blank" rel="noreferrer external">
                <div class="icon-instagram-pos"></div>
            </a>
            <a v-if="company.youtube_link" :href="company.youtube_link" target="_blank" rel="noreferrer external">
                <div class="icon-youtube-pos"></div>
            </a>
            <a v-if="company.pinterest_link" :href="company.pinterest_link" target="_blank" rel="noreferrer external">
                <div class="icon-pinterest-pos"></div>
            </a>
        </li>
    </ul>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';

    export default {
        name: "ProspectContactInformation",
        emits: ['noContent'],
        computed: {
            company() {
                return useProspectStore().prospect;
            },
        },
        mounted() {
            if(
                !this.company.linkedin_link &&
                !this.company.facebook_link &&
                !this.company.twitter_link &&
                !this.company.instagram_link &&
                !this.company.youtube_link &&
                !this.company.pinterest_link
            ) {
                this.$emit('noContent');
            }
        }
    }
</script>