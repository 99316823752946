<template>
    <CustomCheckbox
        :modelValue="modelValue"
        inputKey="prospects_add_tags_checkbox"
        @update:modelValue="onChanged"
        :options="checkboxTags"
        :cssClass="cssClass"
        :showLabel="showLabel"
    />
    <form @submit.prevent="onSubmit" class="form-type-0">
        <CustomInput
            :modelValue="newTags"
            inputKey="prospects_add_tags_input"
            @update:modelValue="handleCommaSeparatedInput"
            :showLabel="showLabel"
        />
        <button v-if="showSubmit" type="submit" class="btn-1">
            <div class="icon-plus-pos"></div>
        </button>
    </form>
</template>

<script>
    import i18n from '@/plugins/i18n';
    import { mapState, mapActions } from 'pinia';
    import { useAccountStore } from '@/stores/account';
    import { useToastStore } from '@/stores/toast';
    import CustomCheckbox from '@/components/elements/inputs/CustomCheckbox';
    import CustomInput from '@/components/elements/inputs/CustomInput';

    export default {
        name: "CustomCheckboxTagsAdd",
        props: {
            modelValue: {
                type: Array,
                default: () => []
            },
            cssClass: {
                type: String,
                default: 'custom-checkbox-type-3'
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
            showSubmit: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                newTags: '',
                localTags: [],
            }
        },
        components: {
            CustomCheckbox,
            CustomInput
        },
        computed: {
            ...mapState(useAccountStore, ['tags']),
            checkboxTags() {
                return this.localTags
                    .filter(tag => tag.count !== undefined && tag.count !== 0)
                    .sort((a, b) => b.count - a.count)
                    .map(tag => ({
                        noTranslate: true,
                        icon: 'tag',
                        ...tag
                    }));
            }
        },
        methods: {
            ...mapActions(useAccountStore, ['getAccountTags']),
            onChanged(newValue) {
                this.$emit('update:modelValue', newValue);
            },
            onSubmit() {
                if (this.newTags.trim()) {
                    this.processTags(this.newTags);
                    this.newTags = '';
                }
            },
            addSuggestion(term) {
                if (!this.localTags.some(tag => tag.value === term)) {
                    this.localTags.push({ value: term, text: term, count: 1 });
                }
            },
            validateTerm(term) {
                const validTermRegex = /^[a-zA-Z0-9 ]+$/;
                if (term.length > 2 && term.length < 19 && validTermRegex.test(term)) {
                    return true;
                } else {
                    useToastStore().addToast(i18n.global.t('toasts.error.tag_formatting'), 'error', null, 5000);
                    return false;
                }
            },
            processTags(newTags) {
                const parts = newTags.split(',').map(part => part.trim()).filter(part => part);
                const validTags = parts.filter(this.validateTerm);
                if (validTags.length) {
                    const updatedModelValue = [...this.modelValue, ...validTags];
                    this.onChanged(updatedModelValue);
                    validTags.forEach(this.addSuggestion);
                }
            },
            handleCommaSeparatedInput(newValue) {
                if (newValue.includes(',')) {
                    this.processTags(newValue);
                    this.newTags = newValue.split(',').pop().trim();
                } else {
                    this.newTags = newValue;
                }
            },
        },
        async mounted() {
            await this.getAccountTags();
            this.localTags = this.tags.map(tag => ({ ...tag }));
        }
    }
</script>