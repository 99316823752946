import { defineStore } from 'pinia';
import { loadLocaleMessages, setI18nLanguage, availableLocales, defaultLocale } from '@/plugins/i18n';

export const useLanguageStore = defineStore('language', {
  state: () => ({
    locale: defaultLocale,
    availableLanguages: availableLocales
  }),
  actions: {
    async setLocale(requestedLocale) {
      const locale = this.availableLanguages.some(lang => lang.code === requestedLocale) ? requestedLocale : defaultLocale;
      const confirmedLocale = await loadLocaleMessages(locale);
      this.locale = confirmedLocale;
      localStorage.setItem('pp_locale', confirmedLocale);
      setI18nLanguage(confirmedLocale);
    },

    async initLocale() {
      const storedLocale = localStorage.getItem('pp_locale');
      const browserLocale = navigator.language.split('-')[0];
      const initialLocale = storedLocale || (this.availableLanguages.some(lang => lang.code === browserLocale) ? browserLocale : defaultLocale);
      await this.setLocale(initialLocale);
    },
  },
});
