<template>
    <div v-if="userStore.hasUserDetails" class="profile-link-type-1">
        <div class="nav-profile-button" @click="handleClick">
            <div class="left">
                <router-link :to="{ name: 'accounts' }">
                    <div class="image">
                        <CompanyLogo 
                            :domain="accountStore.account.domain ? accountStore.account.domain : null"
                            :name="accountStore.account.name ? accountStore.account.name : null"
                        />
                    </div>
                </router-link>
            </div>
            <div class="right">
                <router-link :to="{ name: 'accounts' }">
                    <div class="title">{{ accountStore.account.name ? accountStore.account.name : userStore.user.name ? $t('common.welcome') + ' ' + userStore.user.name : $t('common.welcome') }}</div>
                    <div class="description">{{ userStore.user.email ? userStore.user.email : '-' }}</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { useUserStore } from '@/stores/user';
import { useAccountStore } from '@/stores/account';
import CompanyLogo from '@/components/elements/misc/CompanyLogo';

export default {
    name: 'ProfileNav',
    components: {
        CompanyLogo
    },
    computed: {
        userStore() {
            return useUserStore();
        },
        accountStore() {
            return useAccountStore();
        },
    },
}
</script>
