import { defineStore } from 'pinia';

export const usePopupStore = defineStore('popupStore', {
  state: () => ({
    popupType: null,
    popupProps: {},
  }),
  actions: {
    showPopup(type, props) {
      this.popupType = type;
      this.popupProps = props;
    },
    closePopup() {
      this.popupType = null;
      this.popupProps = {};
    }
  }
});
