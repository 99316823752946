<template>
    <div class="container-fluid content-type-5 prospect">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div v-if="!isFirstProspect" @click="previous" class="btn-1 btn-s-icon prospect-previous">
                            <div class="icon-chevrons-neg"></div>
                        </div>
                        <div @click="close" :class="['btn-5', 'prospect-close', 'btn-m-icon']">
                            <div class="icon-close-pos"></div>
                        </div>
                        <div v-if="!isLastProspect" @click="next" class="btn-1 btn-s-icon prospect-next">
                            <div class="icon-chevrons-neg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-type-5-spacer prospect"></div>
</template>

<script>
    export default {
        name: "ProspectActions",
        emits: ["close", "previous", "next"],
        props: {
            isFirstProspect: {
                type: Boolean,
                default: true
            },
            isLastProspect: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            previous() {
                this.$emit('previous');
            },
            next() {
                this.$emit('next');
            }
        }
    }
</script>
