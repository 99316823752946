<template>
    <ul v-if="openingHours?.length > 0" class="opening-hours-type-1">
        <li v-for="(openingHour, index) in openingHours" :key="index" :class="[openingHour.important ? 'current-day' : '']">
            <div class="day">
                {{ $t(`components.opening_hours.options.${openingHour.day_nr}.${textType}`) }}
            </div>
            <div class="time">
                {{ openingHour.open }} - {{ openingHour.closed }}
            </div>
        </li>
    </ul>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    
    export default {
        name: "ProspectOpeningHours",
        emits: ['noContent'],
        props: {
            textType: {
                type: String,
                default: 'long'
            }
        },
        computed: {
            openingHours() {
                return useProspectStore().openingHours;
            },
        },
        mounted() {
            if(
                !this.openingHours?.length > 0
            ) {
                this.$emit('noContent');
            }
        }
    }
</script>