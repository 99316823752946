<template>
    <CustomSelect
        :modelValue="modelValue"
        :inputKey="inputKey"
        @update:modelValue="onChanged"
        :options="optionsTimePeriod"
    />
</template>

<script>
    import CustomSelect from '@/components/elements/inputs/CustomSelect';

    export default {
        name: "CustomSelectTimePeriod",
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            inputKey: {
                type: String,
                default: 'default.time_periods'
            }
        },
        components: {
            CustomSelect
        },
        data() {
            return {
                optionsTimePeriod: [
                    { value: 'all', text: 'fields.default.time_periods.all' },
                    { value: 'd_7', text: 'fields.default.time_periods.d_7' },
                    { value: 'd_30', text: 'fields.default.time_periods.d_30' },
                    { value: 'd_90', text: 'fields.default.time_periods.d_90' },
                    { value: 'y_current', text: 'fields.default.time_periods.y_current' },
                    { value: 'y_last', text: 'fields.default.time_periods.y_last' },
                ]
            }
        },
        methods: {
            onChanged(newValue) {
                this.$emit('update:modelValue', newValue);
            }
        }
    }
</script>