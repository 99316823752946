export default {
    data() {
        return {
            formError: false,
            formSuccess: false,
            loading: false,
            hasChanged: false
        };
    },
    methods: {
        onInputChange() {
            this.hasChanged = true;

            if (this.formError) {
                this.formError = false;
            }

            if (this.formSuccess) {
                this.formSuccess = false;
            }
        },
        validateAllFields() {
            Object.keys(this.$refs).forEach(ref => {
                if (this.$refs[ref] && typeof this.$refs[ref].triggerValidation === 'function') {
                    this.$refs[ref].triggerValidation();
                }
            });
        },
    }
};
  