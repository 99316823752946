<template>
    <TitleSection cssClass="profile-settings-title">
        <h1>{{ $t('views.profile.title') }}</h1>
        <p>{{ $t('views.profile.description') }}</p>
    </TitleSection>
</template>

<script>
    import TitleSection from '@/components/profile/Title';
    
    export default {
        name: 'ProfileSettingsTitle',
        components: {
            TitleSection
        }
    }
</script>