<template>
    <SettingsSection cssClass="profile-personalia-settings">
        <template #title>
            <h2>{{$t('views.profile.personalia_settings_title')}}</h2>
        </template>

        <template #content>
            <PersonaliaForm :hideSaveButton="true" />
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/profile/Section';
    import PersonaliaForm from '@/components/forms/PersonaliaForm';
    
    export default {
        name: 'ProfilePersonaliaSettings',
        components: {
            SettingsSection,
            PersonaliaForm
        }
    }
</script>