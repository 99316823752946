<template>
    <div :class="['container-fluid header-list', cssClass]">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-5 col-md-offset-1 col-xl-6 col-xl-offset-0">
                    <slot name="content"></slot>
                </div>
                <div v-if="showActions" class="col-12 col-md-5 col-xl-6">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
    </div>
    <div v-if="includeSpacer" :class="['header-list-spacer', cssClass + '-spacer']"></div>
</template>

<script>
    export default {
            name: 'HeaderList',
            props: {
                cssClass: {
                    type: String
                },
                showActions: {
                    type: Boolean,
                    default: true
                },
                includeSpacer: {
                    type: Boolean,
                    default: false
                }
            }
    }
</script>