<template>
    <div class="form-type-0">
        <form @submit.prevent="register" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="name"
                inputKey="name"
                type="text"
                ref="name"
                autocomplete="organization"
                required
            />
            <CustomInput
                v-model="url"
                inputKey="url"
                type="url"
                ref="url"
                autocomplete="url"
                required
            />
            <CustomInput
                v-model="email"
                inputKey="email"
                type="email"
                ref="email"
                autocomplete="email"
                required
            />
            <CustomInput
                v-model="password"
                inputKey="password_select"
                type="password"
                ref="password"
                autocomplete="new-password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}" 
                required
            />
            <CustomToggle
                v-model="selected_options"
                inputKey="options_legal"
                ref="options-legal"
                :options="checkboxOptions"
                required
            />
            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.register.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.register.error') }}</p>
        </form>
    </div>
</template>

<script>
    import { useUserStore } from '@/stores/user';
    import formMixin from '@/mixins/formMixin';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CustomToggle from '@/components/elements/inputs/CustomToggle';

    export default {
        mixins: [formMixin],
        components: {
            CustomInput,
            CustomToggle
        },
        data() {
            return {
                name: '',
                url: '',
                email: '',
                password: '',
                selected_options: [],
                checkboxOptions: [
                    { value: 'tc', text: 'fields.options_legal.options.tc', required: true },
                ]
            };
        },
        computed: {
            userStore() {
                return useUserStore();
            }
        },
        methods: {
            async register() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;
                    const success = await this.userStore.register(this.name, this.url, this.email, this.password);
                    this.loading = false;

                    if (success) {
                        form.reset();
                        this.userStore.setRedirectAfterLogin();
                        this.userStore.redirectUserAfterLogin();
                        this.formError = false;
                    } else {
                        this.formError = true;
                    }
                } else {
                    this.validateAllFields();
                }
            }
        }
    }
</script>