<template>
    <div class="form-type-0">
        <form @submit.prevent="resetPassword" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="email"
                inputKey="email"
                type="email"
                ref="email"
                autocomplete="email"
                required
            />

            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.password_reset.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.password_reset.error') }}</p>
            <p v-if="formSuccess" class="form-success">{{ $t('forms.password_reset.success') }}</p>
        </form>
    </div>
</template>

<script>
    import { useUserStore } from '@/stores/user';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import formMixin from '@/mixins/formMixin'

    export default {
        mixins: [formMixin],
        components: {
            CustomInput
        },
        data() {
            return {
                email: '',
            };
        },
        computed: {
            userStore() {
                return useUserStore();
            }
        },
        methods: {
            async resetPassword() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;
                    const success = await this.userStore.passwordReset(this.email);
                    this.loading = false;

                    if (success) {
                        form.reset();
                        this.formSuccess = true;
                        this.formError = false;

                    } else {
                        this.formError = true;
                    }

                } else {
                    this.validateAllFields();
                }
            }
        }
    }
</script>
