<template>
    <template v-if="mainNav.showNav">
        <div class="nav-type-1-spacer"></div>
        <div 
            :class="['nav-type-1', 'container-fluid', { open: navExpanded }]"
            ref="topNav"
        >
            <div class="container">
                <div class="nav-left">
                    <router-link :to="{ name: 'suspect' }">
                        <div class="nav-logo logo-pos"></div>
                    </router-link>
                </div>
                <div class="nav-right">
                    <ProfileLink />
                    <HamburgerButton v-model="navExpanded" />
                </div>
            </div>
        </div>
        <div v-if="navExpanded" class="nav-type-1-expanded" ref="topNavExp">
                <div class="nav-center">
                    <CustomMenu class="nav-menu-main" :links="navLinks" />
                </div>

                <div class="nav-bottom">
                    <CustomMenu class="nav-menu-auth" :links="navLinksAuth" />
                </div>
        </div>
    </template>
</template>

<script>
    import { useUserStore } from '@/stores/user';
    import CustomMenu from '@/components/elements/navigation/CustomMenu';
    import ProfileLink from '@/components/elements/navigation/ProfileLink';
    import HamburgerButton from '@/components/elements/misc/HamburgerButton';
    import { useMainNavStore } from '@/stores/mainNav';

    export default {
        name: 'TopNav',
        components: {
            CustomMenu,
            HamburgerButton,
            ProfileLink
        },
        data() {
            return {
                navExpanded: false,
                navLinks: [
                    {
                        route: { name: 'suspect' },
                        text: 'links.suspect',
                        icon: 'cards'
                    },
                    {
                        route: { name: 'prospects' },
                        text: 'links.prospects',
                        icon: 'target'
                    },
                ],
                navLinksAuth: [
                    {
                        route: { name: 'settings' },
                        text: 'links.settings',
                        icon: 'settings'
                    },
                    {
                        route: { name: 'users' },
                        text: 'links.users',
                        icon: 'team'
                    },
                    {
                        url: "https://www.prospectpro.nl/klantenservice/",
                        text: 'links.customer_service',
                        icon: 'information'
                    },
                    {
                        function: this.logout,
                        text: 'links.signout',
                        icon: 'signout',
                        class: 'danger'
                    }
                ],
            }
        },
        computed: {
            userStore() {
                return useUserStore();
            },
            mainNav() {
                return useMainNavStore();
            }
        },
        methods: {
            toggleExpand(show = null) {
                this.navExpanded = typeof(show) === 'boolean' ? show : !this.navExpanded;
            },
            handleClick() {
                this.toggleExpand();
            },
            handleClickOutside(event) {
                if (this.navExpanded && !this.$refs.topNav.contains(event.target) && !this.$refs.topNavExp.contains(event.target)) {
                    this.toggleExpand(false);
                }
            },
            logout() {
                this.userStore.logout();
            },
        },
        watch: {
            $route() {
                this.toggleExpand(false);
            }
        },
        beforeMount() {
            document.addEventListener('click', this.handleClickOutside);
        },
        beforeUnmount() {
            document.removeEventListener('click', this.handleClickOutside);
            this.toggleExpand(false);
        },
    }
</script>