<template>
    <div class="form-type-0">
        <button v-if="addToggleStage && !showForm" @click="toggleForm">{{ $t('forms.password_change.button') }}</button>
        <form v-else @submit.prevent="passwordChange" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="password"
                inputKey="password"
                type="password"
                ref="password"
                autocomplete="current-password"
                required
            />
            <CustomInput
                v-model="passwordNew"
                inputKey="password_new"
                type="password"
                ref="password-new"
                autocomplete="new-password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}" 
                required
            />
            <CustomInput
                v-model="passwordConfirm"
                inputKey="password_confirm"
                type="password"
                ref="passwordConfirm"
                autocomplete="new-password"
            />

            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.password_change.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.password_change.error') }}</p>
            <p v-if="formSuccess" class="form-success">{{ $t('forms.password_change.success') }}</p>
        </form>
    </div>
</template>

<script>
    import { useUserStore } from '@/stores/user';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import formMixin from '@/mixins/formMixin';

    export default {
        mixins: [formMixin],
        components: {
            CustomInput
        },
        props: {
            addToggleStage: {
            type: Boolean,
            default: false
            }
        },
        data() {
            return {
                password: '',
                passwordNew: '',
                passwordConfirm: '',
                showForm: !this.addToggleStage,
            };
        },
        computed: {
            userStore() {
                return useUserStore();
            }
        },
        methods: {
            async passwordChange() {
                const form = this.$refs.form;
                if(form.checkValidity() && this.validateConfirmPassword()) {
                    this.loading = true;
                    const success = await this.userStore.passwordChange(this.password, this.passwordNew, this.passwordConfirm);
                    this.loading = false;

                    if (success) {
                        form.reset();
                        this.formSuccess = true;
                        this.formError = false;
                    } else {
                        this.formError = true;
                    }
                } else if (!this.validateConfirmPassword()) {
                    this.validateAllFields();
                    this.$refs.passwordConfirm.inputError = true;
                } else {
                    this.validateAllFields();
                }
            },
            validateConfirmPassword() {
                return this.passwordNew === this.passwordConfirm;
            },
            toggleForm() {
                this.showForm = true;
                this.$nextTick(() => {
                    const defaultElement = this.$refs.password.$el.querySelector('input');
                    const inputRect = defaultElement.getBoundingClientRect();
                    const absoluteElementTop = inputRect.top + window.pageYOffset;
                    const scrollToPosition = absoluteElementTop - 96;
                    window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
                    defaultElement.focus();
                });
            }
        },
    }
</script>
