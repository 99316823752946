<template>
	<div class="password-reset-view">
		<AuthSection>
			<router-link class="logo-link" :to="{ name: 'home' }">
				<div class="logo-pos"></div>
			</router-link>
			<h1 class="h4">{{ $t(title) }}</h1>
			<p class="description">{{ $t(description) }}</p>
			<PasswordResetForm />
			<p class="notice">
				{{ $t(found_password_notice) }}
				<router-link class="link" :to="{ name: 'login' }">{{ $t(found_password_link_text) }}</router-link>.
			</p>
		</AuthSection>
	</div>
</template>

<script>
	import i18nViewMixin from '@/mixins/i18nViewMixin';
	import AuthSection from '@/components/sections/AuthSection';
	import PasswordResetForm from '@/components/forms/PasswordResetForm.vue';

	export default {
		name: 'PasswordResetView',
		mixins: [i18nViewMixin],
		components: {
			AuthSection,
			PasswordResetForm
		},
		data() {
			return {
				seo_title: 'views.password_reset.seo_title',
				title: 'views.password_reset.title',
				description: 'views.password_reset.description',
				found_password_notice: 'notices.found_password.notice',
				found_password_link_text: 'notices.found_password.link_text',
			};
		}
	}
</script>