<template>
    <CustomCheckbox
        v-if="!hasDefaultTags"
        :modelValue="modelValue"
        inputKey="prospects_filter_tags"
        @update:modelValue="onChanged"
        :options="checkboxTags"
        cssClass="custom-checkbox-type-5"
        :showLabel="showLabel"
    />
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'pinia';
    import { useAccountStore } from '@/stores/account';
    import CustomCheckbox from '@/components/elements/inputs/CustomCheckbox';

    export default {
        name: "CustomCheckboxTagsFilter",
        props: {
            modelValue: {
                type: Array,
                default: () => []
            },
            showLabel: {
                type: Boolean,
                default: true,
            },
        },
        components: {
            CustomCheckbox
        },
        computed: {
            ...mapState(useAccountStore, ['tags']),
            ...mapGetters(useAccountStore, ['hasDefaultTags']),
            checkboxTags() {
                var result = this.tags.map(tag => ({
                    noTranslate: true,
                    icon: 'tag',
                    ...tag
                }));
                return result.filter(tag => tag.count && tag.count > 0);
            }
        },
        methods: {
            ...mapActions(useAccountStore, ['getAccountTags']),
            onChanged(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
        async mounted() {
            await this.getAccountTags();
        }
    }
</script>