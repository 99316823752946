<template>
    <div :class="[cssClass, open ? 'open' : 'closed']">
        <div class="toggle" @click="toggleOptions(null, event)">
            <slot name="toggle"></slot>
        </div>
        <div v-if="open" class="toggle-content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ToggleSimple",
        props: {
            default: {
                type: Boolean,
                default: false
            },
            cssClass: {
                type: String,
                default: 'toggle-type-1'
            }
        },
        data() {
            return {
                open: this.default || false
            }
        },
        methods: {
            toggleOptions(show = null, event = null) {
                if (event) { event.stopPropagation(); } // Prevent timing issues!
                this.open = show !== null ? show : !this.open;
            },
        }
    }
</script>