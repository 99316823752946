<template>
    <div class="no-results-section-type-1">
        <div class="no-results-text" v-html="$te(message) ? $t(message, translationPlaceholders) : $t('common.no_results_1')"></div>
    </div>
</template>

<script>
export default {
    name: "NoResultsSection",
    props: {
        message: {
            type: String,
            required: false
        },
        translationPlaceholders: {
            type: Object,
            default: () => ({})
        },
    }
}
</script>