<template>
    <div :class="['custom-radio', cssClass]">
        <label v-if="showLabel && $te(label)" :for="uniqueId" :class="['input-label', { required: required }]">{{ $t(label) }}</label>
        <ul :ref="inputKey">
            <li v-for="option in options" :key="option.value" class="custom-radio-option">
                <input
                    type="radio"
                    :id="`${uniqueId}-${option.value}`"
                    :name="inputKey"
                    :value="option.value"
                    :checked="modelValue === option.value"
                    :required="required"
                    @change="onInput"
                />
                <label :for="`${uniqueId}-${option.value}`" :class="['option-label', modelValue === option.value ? 'selected' : 'not-selected']">
                    <div class="custom-radio-icon"></div>
                    <div class="custom-radio-text" v-html="$t(option.text)"></div>
                </label>
            </li>
        </ul>
        <p v-if="inputError" class="input-error">{{ $te(error) ? $t(error) : $t('fields.default.radio.error') }}</p>
    </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
    props: {
        inputKey: String,
        modelValue: [String, Number],
        required: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        cssClass: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            inputError: false,
            label: `fields.${this.inputKey}.label`,
            error: `fields.${this.inputKey}.error`,
            uniqueId: `${this.inputKey}-${Math.random().toString(36).substr(2, 9)}`
        };
    },
    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.value);
            nextTick(() => {
                this.validateInput(event.target.value);
            });
        },
        validateInput(value) {
            const isValidOption = this.options.some(option => option.value === value);
            const input = this.$refs[this.inputKey];

            if (this.required && (!value || !isValidOption)) {
                this.inputError = true;
                input.classList.add('is-invalid');
                input.classList.remove('is-valid');
                return false;
            } else {
                this.inputError = false;
                input.classList.remove('is-invalid');
                input.classList.add('is-valid');
                return true;
            }
        },
        triggerValidation() {
            this.validateInput(this.modelValue);
        }
    },
};
</script>
