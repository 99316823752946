<template>
    <OptionsSuspectPanel>
        <template #options-default>
            <div class="logo">
                <div class="logo-pos"></div>
            </div>
            <div class="form-type-2">
                <OptionsSearch v-model="options.search" @update:modelValue="onChanged" />
            </div>
        </template>
        <template #options-full>
            <div class="form-type-2">
                <OptionsEmployees v-model="options.employees" @update:modelValue="onChanged" />
                <OptionsFounded v-model="options.founded" @update:modelValue="onChanged" />
                <OptionsLocation v-model="options.location" @update:modelValue="onChanged" />
                <OptionsApps v-model="options.apps" @update:modelValue="onChanged" />
                <OptionsSocials v-model="options.socials" @update:modelValue="onChanged" />
            </div>
        </template>
    </OptionsSuspectPanel>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useSuspectStore } from '@/stores/suspect';
    import OptionsSuspectPanel from '@/components/panels/optionsSuspect/OptionsSuspectPanel';
    import OptionsSearch from '@/components/elements/suspect/OptionsSearch';
    import OptionsSocials from '@/components/elements/suspect/OptionsSocials';
    import OptionsLocation from '@/components/elements/suspect/OptionsLocation';
    import OptionsApps from '@/components/elements/suspect/OptionsApps';
    import OptionsEmployees from '@/components/elements/suspect/OptionsEmployees';
    import OptionsFounded from '@/components/elements/suspect/OptionsFounded';

    export default {
        name: "SuspectOptions",
        components: {
            OptionsSuspectPanel,
            OptionsSearch,
            OptionsSocials,
            OptionsLocation,
            OptionsApps,
            OptionsEmployees,
            OptionsFounded
        },
        computed: {
            ...mapState(useSuspectStore, ['options']),
        },
        methods: {
            ...mapActions(useSuspectStore, ['updateOptions', 'resetOptions']),
            onChanged() {
                this.updateOptions(this.options);
            },
        },
    }
</script>