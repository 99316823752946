<template>
    <div class="container-fluid pagination-type-1" v-if="totalPages > 1">
        <div class="container">
            <div class="pagination">
                <div class="container-nav">
                    <ul class="navigation">
                        <li v-for="page in pageRange" :key="page" @click="changePage(page)" :class="['pagination-button', 'pagination-page', { 'pagination-active': pagination.page === page, 'pagination-inactive': pagination.page !== page, 'pagination-divider': page === '...' }]">
                            <span>{{ page }}</span>
                        </li>
                    </ul>
                </div>

                <div class="container-arrows">
                    <ul class="arrows">
                        <li v-if="pagination.page > 1" class="pagination-button pagination-previous" @click="changePage(pagination.page - 1)">
                            <span>{{ $t('fields.pagination.previous') }}</span>
                        </li>
                        <li v-if="pagination.page < totalPages" class="pagination-button pagination-next" @click="changePage(pagination.page + 1)">
                            <span>{{ $t('fields.pagination.next') }}</span>
                        </li>
                        <li v-if="pagination.page > 1 && firstLast" class="pagination-button pagination-first" @click="changePage(1)">
                            <span>{{ $t('fields.pagination.first') }}</span>
                        </li>
                        <li v-if="pagination.page < totalPages && firstLast" class="pagination-button pagination-last" @click="changePage(totalPages)">
                            <span>{{ $t('fields.pagination.last') }}</span>
                        </li>
                    </ul>
                </div>

                <div class="container-limit">
                    <div class="form-type-1 select-limit">
                        <CustomSelect
                            v-model="selectedLimit"
                            inputKey="limit_per_page"
                            :options="optionsLimit"
                            ref="limit"
                            @change="updateLimitPerPage"
                        />
                        <div class="limit-text">{{ $t('fields.pagination.per_page') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pagination-spacer"></div>
</template>

<script>
import CustomSelect from '@/components/elements/inputs/CustomSelect';

export default {
  name: "PaginationPanel",
  emits: ["update-pagination"],
  components: {
      CustomSelect
  },
  props: {
    pagination: {
      type: Object,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      pageSizes: [1, 10, 20, 50],
      selectedLimit: this.pagination.limit,
      firstLast: false
    };
  },
  computed: {
    optionsLimit() {
        return this.pageSizes.map(pageSize => {
            return {
                text: 'fields.pagination.options.' + pageSize,
                value: pageSize + ''
            };
        });
    },
    totalPages() {
        return Math.ceil(this.total / this.pagination.limit);
    },
    pageRange() {
        const range = [];
        const start = Math.max(1, this.pagination.page - 1);
        const end = Math.min(this.totalPages, this.pagination.page + 1);
        
        if (start > 1) range.push(1);
        if (start > 2) range.push('...');
        for (let i = start; i <= end; i++) {
            range.push(i);
        }
        if (end < this.totalPages - 1) range.push('...');
        if (end < this.totalPages) range.push(this.totalPages);
        return range;
    }
  },
  methods: {
    changePage(page) {
        if(page != '...' && this.pagination.page != page) {
            this.$emit('update-pagination', { ...this.pagination, page });
        }
    },
    updateLimitPerPage() {
      this.$emit('update-pagination', { ...this.pagination, limit: this.selectedLimit, page: 1 });
    }
  }
};
</script>