<template>
    <SettingsSection cssClass="profile-password-settings">
        <template #title>
            <h2>{{$t('views.profile.password_settings_title')}}</h2>
        </template>

        <template #content>
            <PasswordChangeForm :addToggleStage="true" />
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/profile/Section';
    import PasswordChangeForm from '@/components/forms/PasswordChangeForm';
    
    export default {
        name: 'ProfilePasswordSettings',
        components: {
            SettingsSection,
            PasswordChangeForm
        }
    }
</script>