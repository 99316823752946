<template>
    <CustomRadio
        :modelValue="label"
        inputKey="label"
        @update:modelValue="onLabelChanged"
        :options="optionsLabel"
        :showLabel="false"
        cssClass="custom-radio-type-3"
    />
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import CustomRadio from '@/components/elements/inputs/CustomRadio';

    export default {
        name: "ProspectLabel",
        components: {
            CustomRadio
        },
        data() {
            return {
                optionsLabel: [
                    { value: 1, text: 'fields.label.options.1' },
                    { value: -1, text: 'fields.label.options.-1' },
                    { value: 0, text: 'fields.label.options.0' },
                ]
            }
        },
        computed: {
            company() {
                return useProspectStore().prospect;
            },
            label() {
                return this.company?.label || this.company?.label === 0 ? this.company.label : '';
            },
        },
        methods: {
            onLabelChanged(label) {
                useProspectStore().updateLabel(label);
            }
        }
    }
</script>