<template>
    <div :class="['autosuggest-type-1', suggestions.length > 0 ? 'active' : '']">
       <div class="autosuggest-input">
            <CustomInput
                :modelValue="search"
                inputKey="suspect_search"
                @update:modelValue="onChanged"
                @keydown.down.prevent="highlightNext"
                @keydown.up.prevent="highlightPrevious"
                @keydown.enter.prevent="selectHighlighted"
                :showLabel="false"
                cssClass="input-search"
            />

            <div class="autosuggest-options" v-if="suggestions.length > 0" ref="suggestionsList">
                <ul @click.stop>
                    <li 
                        v-for="(suggestion, index) in suggestions" 
                        :key="suggestion.term"
                        :class="{ 'highlighted': index === highlightedIndex }"
                        @click="selectSuggestion(suggestion.term)"
                        @mouseover="highlightIndex(index)"
                    >
                        {{ suggestion.term }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { mapActions } from 'pinia';
    import { fetchSuggestions } from '@/services/ppApi';
    import { useAccountStore } from '@/stores/account';
    import { useSuspectStore } from '@/stores/suspect';
    import CustomInput from '@/components/elements/inputs/CustomInput';

    export default {
        name: "OptionsSearch",
        components: {
            CustomInput
        },
        props: {
            modelValue: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                search: this.modelValue || '',
                suggestions: [],
                highlightedIndex: -1,
                label: `fields.suspect_search.label`
            };
        },
        methods: {
            ...mapActions(useSuspectStore, ['fetchSuspect']),
            onChanged: debounce(function(newValue) {
                this.search = newValue;
                this.getSuggestions(newValue);
                this.setValue();
            }, 300),
            async getSuggestions(newValue) {
                if(newValue.length < 1 || newValue.length > 10) {
                    this.suggestions = [];
                    return;
                }

                try {
                    const accountStore = useAccountStore();
                    const response = await fetchSuggestions({
                        account_id: accountStore.account.account_id,
                        type: 'text',
                        q: newValue
                    });
                    this.suggestions = response.data.suggest;
                } catch {
                    this.suggestions =  [];
                } finally {
                    this.highlightedIndex = -1;
                }
            },
            selectSuggestion(term) {
                this.search = term;
                this.setValue();
                this.closeSuggestionList();
            },
            setValue() {
                this.$emit('update:modelValue', this.search);
            },
            handleClickOutside(event) {
                if (this.$refs.suggestionsList && !this.$refs.suggestionsList.contains(event.target)) {
                    this.closeSuggestionList();
                }
            },
            closeSuggestionList() {
                this.suggestions = [];
                this.highlightedIndex = -1;
            },
            highlightNext() {
                if (this.highlightedIndex < this.suggestions.length - 1) {
                    this.highlightedIndex++;
                }
            },
            highlightPrevious() {
                if (this.highlightedIndex > 0) {
                    this.highlightedIndex--;
                }
            },
            selectHighlighted() {
                if (this.highlightedIndex >= 0 && this.highlightedIndex < this.suggestions.length) {
                    this.selectSuggestion(this.suggestions[this.highlightedIndex].term);
                } else if (this.highlightedIndex === -1) {
                    this.fetchSuspect();
                }
            },
            highlightIndex(index) {
                this.highlightedIndex = index;
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
        },
        unmounted() {
            document.removeEventListener('click', this.handleClickOutside);
        },
    }
</script>