<template>
    <div :class="['row', cssClass]">
        <div class="col-12 col-md-9 col-md-offset-3 col-xl-offset-4">
            <div class="page-title">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
            name: 'ProfileTitleSection',
            props: {
                cssClass: {
                    type: String
                },
            }
    }
</script>