<template>
    <!-- Hook this up to user role for access management -->
    <HeaderList :showActions="true" cssClass="header-users">
        <template #content>
            <div class="content">
                <h1>{{ $t('views.users.title') }}</h1>
                <p>{{ $t('views.users.description', { accountName: accountStore.account.name }) }}</p>
            </div>
        </template>

        <template #actions>
            <div class="actions">
                <UserCreate />
            </div>
        </template>
    </HeaderList>
</template>

<script>
import HeaderList from '@/components/sections/HeaderList';
import UserCreate from '@/components/account/users/UserCreate';
import { useAccountStore } from '@/stores/account';

export default {
    name: "UserHeader",
    components: {
        HeaderList,
        UserCreate
    },
    computed: {
        accountStore() {
            return useAccountStore();
        }
    }
}
</script>