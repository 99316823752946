<template>
    <div :class="['custom-input', `input-${type}`, cssClass, disabled ? 'input-disabled' : '']">
        <label v-if="showLabel && $te(label)" :for="uniqueId" :class="['input-label', { required: required }]">{{ $t(label) }}</label>
        <input
            :value="modelValue"
            :id="uniqueId"
            :ref="inputKey"
            :name="inputKey"
            :type="type"
            :placeholder="$t(placeholder)"
            :required="required"
            :pattern="pattern"
            :min="min"
            :max="max"
            @input="onInput"
            :autocomplete="autocomplete"
            :disabled="disabled"
        />
        <p v-if="inputError && showError" class="input-error">{{ $te(error) ? $t(error) : $t('fields.default.input.error') }}</p>
    </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
    props: {
        inputKey: String,
        modelValue: [String, Number],
        type: {
            type: String,
            default: 'text',
        },
        cssClass: {
            type: String,
            default: '',
            required: false
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        showError: {
            type: Boolean,
            default: true
        },
        autocomplete: {
            type: String,
            required: false
        },
        pattern: String,
        min: [String, Number],
        max: [String, Number]
    },
    data() {
        return {
            inputError: false,
            placeholder: `fields.${this.inputKey}.placeholder`,
            label: `fields.${this.inputKey}.label`,
            error: `fields.${this.inputKey}.error`,
            uniqueId: `${this.inputKey}-${Math.random().toString(36).substr(2, 9)}`
        };
    },
    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.value);
            nextTick(() => {
                this.validateInput(event.target);
            });
        },
        validateInput(input) {
            if (!input.checkValidity()) {
                this.inputError = true;
                input.classList.add('is-invalid');
                input.classList.remove('is-valid');
                return false;
            } else {
                this.inputError = false;
                input.classList.remove('is-invalid');
                input.classList.add('is-valid');
                return true;
            }
        },
        triggerValidation() {
            const input = this.$refs[this.inputKey];
            if (input) {
                return this.validateInput(input);
            } else {
                // Nothing to see here.
                return true;
            }
        }
    },
};

</script>