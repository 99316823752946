<template>
  <div :class="cssClass">
    <div class="popup-container">
      <div v-if="showCloseIcon" class="close-icon" @click="closePopup">
          <div class="icon-close-pos"></div>
      </div>
      <div class="content">
        <h3>{{ translationPlaceholders && translationPlaceholders.title ? $t(title, translationPlaceholders.title ) : $t(title) }}</h3>
        <p>{{ translationPlaceholders && translationPlaceholders.description ? $t(description, translationPlaceholders.description ) : $t(description) }}</p>
        <button @click="closePopup" class="btn-m btn-1">{{ $t(closeButtonText) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { usePopupStore } from '@/stores/popup';
import {mapActions } from 'pinia';

export default {
  props: {
    title: String,
    description: String,
    closeButtonText: {
      type: String,
      default: 'common.ok_1'
    },
    showCloseIcon: {
      type: Boolean,
      default: true
    },
    cssClass: {
      type: String,
      default: 'popup-type-1'
    },
    translationPlaceholders: {
        type: Object,
        default: () => ({})
    },
  },
  methods: {
      ...mapActions(usePopupStore, ['closePopup']),
  }
};
</script>
