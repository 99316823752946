<template>
    <WidgetSection v-if="!hide" cssClass="content-type-9">
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_tags.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="tags">
                    <CustomCheckboxTagsAdd
                        :modelValue="tags"
                        @update:modelValue="onTagsChanged"
                        :showLabel="false"
                        cssClass="custom-checkbox-type-5"
                    />
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import CustomCheckboxTagsAdd from '@/components/elements/inputs/CustomCheckboxTagsAdd';
    import WidgetSection from '@/components/sections/WidgetSection';

    export default {
        name: "ProspectTags",
        components: {
            WidgetSection,
            CustomCheckboxTagsAdd,
        },
        computed: {
            company() {
                return useProspectStore().prospect;
            },
            tags() {
                return this.company?.tags && this.company.tags != '' ? this.company.tags.split('|') : [];
            },
            hide() {
                return false;
            }
        },
        methods: {
            onTagsChanged(tags) {
                useProspectStore().updateTags(tags);
            }
        }
    }
</script>