<template>
    <ul v-if="company" class="specs-type-1 contact-information">
        <li v-if="company.url">
            <div class="title">
                {{ $t('common.website')}}
            </div>
            <div class="content">
                <a v-if="company.url" :href="company.url" target="_blank" rel="noreferrer external">
                    {{ company.url }}
                </a>
            </div>
        </li>
        <li v-if="company.phone_international">
            <div class="title">
                {{ $t('common.phone')}}
            </div>
            <div class="content">
                <a :href="'tel:' + company.phone_international.replace('+', '00').replaceAll(' ', '')" target="_blank" rel="external">
                    {{ company.phone_international }}
                </a> 
            </div>
        </li>
        <li v-if="company.email">
            <div class="title">
                {{ $t('common.email')}}
            </div>
            <div class="content">
                <a :href="'mailto:' + company.email" target="_blank" rel="external">
                    {{ company.email }}
                </a> 
            </div>
        </li>
        <li v-if="company.address">
            <div class="title">
                {{ $t('common.address')}}
            </div>
            <div class="content">
                {{ company.address }}
            </div>
            <div class="content" v-if="company.postcode || company.city">
                {{ company.postcode }}
                {{ company.city }}
            </div>
        </li>
        <li v-if="location?.google_route || location?.google_streetview || location?.bing_route" class="links">
            <div class="content">
                <a :href="location.google_route" target="_blank" rel="noreferrer external">{{ $t('common.google_maps')}}</a>
            </div>
            <div class="content">
                <a :href="location.google_streetview" target="_blank" rel="noreferrer external">{{ $t('common.google_streetview')}}</a>
            </div>
            <div class="content">
                <a :href="location.bing_route" target="_blank" rel="noreferrer external">{{ $t('common.bing_maps')}}</a>
            </div>
        </li>
    </ul>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';

    export default {
        name: "ProspectContactInformation",
        computed: {
            company() {
                return useProspectStore().prospect;
            },
            location() {
                return useProspectStore().location;
            },
        }
    }
</script>