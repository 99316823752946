<template>
    <WidgetSection :loading="loading" v-if="!hide">
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_siterank.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div v-if="hasLighthouse" class="row row-content row-native-cols">
                <div v-if="siterank.details?.pagespeed_mobile_score?.performance" class="col-6 col-md-4 col-lg-3">
                    <div class="gauge">
                        <GraphGauge :value="Math.round(parseFloat(siterank.details.pagespeed_mobile_score.performance) * 100)" />
                        <div class="title">{{ $t('components.prospect_siterank.lh_performance')}}</div>
                    </div>
                </div>
                <div v-if="siterank.details?.pagespeed_mobile_score?.seo" class="col-6 col-md-4 col-lg-3">
                    <div class="gauge">
                        <GraphGauge :value="Math.round(parseFloat(siterank.details.pagespeed_mobile_score.seo) * 100)" />
                        <div class="title">{{ $t('components.prospect_siterank.lh_seo')}}</div>
                    </div>
                </div>
                <div v-if="siterank.details?.pagespeed_mobile_score?.accessibility" class="col-6 col-md-4 col-lg-3">
                    <div class="gauge">
                        <GraphGauge :value="Math.round(parseFloat(siterank.details.pagespeed_mobile_score.accessibility) * 100)" />
                        <div class="title">{{ $t('components.prospect_siterank.lh_accessibility')}}</div>
                    </div>
                </div>
                <div v-if="siterank.details?.pagespeed_mobile_score?.best_practices" class="col-6 col-md-4 col-lg-3">
                    <div class="gauge">
                        <GraphGauge :value="Math.round(parseFloat(siterank.details.pagespeed_mobile_score.best_practices) * 100)" />
                        <div class="title">{{ $t('components.prospect_siterank.lh_best_practices')}}</div>
                    </div>
                </div>
            </div>

            <div v-if="hasStatistics" class="row row-content">
                <ul class="specs-type-1">
                    <li v-if="siterank?.details?.monthly_visits">
                        <div class="title">
                            {{ $t('components.prospect_siterank.monthly_visits')}}*
                        </div>
                        <div class="content capitalize">
                            {{ siterank.details.monthly_visits }}
                        </div>
                    </li>
                    <li v-if="siterank?.details?.bounce_rate">
                        <div class="title">
                            {{ $t('components.prospect_siterank.bounce_rate')}}*
                        </div>
                        <div class="content capitalize">
                            {{ siterank.details.bounce_rate }}
                        </div>
                    </li>
                    <li v-if="siterank?.details?.time_on_site">
                        <div class="title">
                            {{ $t('components.prospect_siterank.time_on_site')}}*
                        </div>
                        <div class="content capitalize">
                            {{ siterank.details.time_on_site }}s
                        </div>
                    </li>
                    <li v-if="siterank?.details?.visited_pages">
                        <div class="title">
                            {{ $t('components.prospect_siterank.visited_pages')}}*
                        </div>
                        <div class="content capitalize">
                            {{ siterank.details.visited_pages }}
                        </div>
                    </li>
                    <li v-if="siterank?.details?.pagerank">
                        <div class="title">
                            {{ $t('components.prospect_siterank.pagerank')}}
                        </div>
                        <div class="content capitalize">
                            {{ siterank.details.pagerank }}
                        </div>
                    </li>
                    <li v-if="siterank?.details?.backlinks">
                        <div class="title">
                            {{ $t('components.prospect_siterank.backlinks')}}
                        </div>
                        <div class="content capitalize">
                            {{ siterank.details.backlinks }}
                        </div>
                    </li>
                    <li v-if="siterank?.details?.backlink_domains">
                        <div class="title">
                            {{ $t('components.prospect_siterank.backlink_domains')}}
                        </div>
                        <div class="content capitalize">
                            {{ siterank.details.backlink_domains }}
                        </div>
                    </li>
                    <li v-if="siterank?.details?.indexable_urls">
                        <div class="title">
                            {{ $t('components.prospect_siterank.indexable_urls')}}
                        </div>
                        <div class="content capitalize">
                            {{ siterank.details.indexable_urls }}
                        </div>
                    </li>
                    <li class="disclaimer" v-if="siterank?.details?.monthly_visits || siterank?.details?.bounce_rate || siterank?.details?.time_on_site || siterank?.details?.visited_pages">
                        * {{ $t('components.prospect_siterank.disclaimer')}}
                    </li>
                </ul>
            </div>

        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { fetchUrl } from '@/services/ppApi';
    import WidgetSection from '@/components/sections/WidgetSection';
    import GraphGauge from '@/components/elements/misc/GraphGauge';

    export default {
        name: "ProspectApps",
        components: {
            WidgetSection,
            GraphGauge
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {
            siterank() {
                return useProspectStore().siterank;
            },
            hide() {
                return !this.loading && (!this.hasLighthouse || !this.hasStatistics);
            },
            hasLighthouse() {
                const details = this.siterank?.details || {};
                return Object.keys(details).some(key => key.startsWith('pagespeed_'));
            },
            hasStatistics() {
                const details = this.siterank?.details || {};
                return ['monthly_visits', 'bounce_rate', 'time_on_site', 'visited_pages', 'pagerank', 'backlinks', 'backlink_domains', 'indexable_urls'].some(key => key in details);
            }
        },
        methods: {
            async fetchSiterank() {
                if (!this.siterank || (this.siterank.api_urls && this.siterank.api_urls.length < 1)) {
                    return;
                }
                this.loading = true;

                try {
                    const response = await fetchUrl(this.siterank.api_urls[0]);
                    this.siterank.details = response.data;
                } catch {
                    this.siterank.details = null;
                } finally {
                    this.loading = false;
                }
            }
        },
        mounted() {
            this.fetchSiterank();
        }
    }
</script>