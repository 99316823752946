<template>
    <div :class="['container-fluid', 'content-type-2']">
        <div class="container">
            <SettingsTitle />
            <GravatarSection />
            <PersonaliaSection />
            <LanguageSection />
            <PasswordSection />
        </div>
    </div>
</template>

<script>
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import { useAccountStore } from '@/stores/account';
    import SettingsTitle from '@/components/profile/SettingsTitle';
    import LanguageSection from '@/components/profile/LanguageSettings';
    import PasswordSection from '@/components/profile/PasswordSettings';
    import PersonaliaSection from '@/components/profile/PersonaliaSettings';
    import GravatarSection from '@/components/profile/GravatarSettings';

    export default {
        name: 'ProfileView',
        mixins: [i18nViewMixin],
        components: {
            SettingsTitle,
            PersonaliaSection,
            LanguageSection,
            PasswordSection,
            GravatarSection
        },
        data() {
            return {
                seo_title: 'views.profile.seo_title',
            };
        },
        computed: {
            accountStore() {
                return useAccountStore();
            },
        },
        created() {
            this.accountStore.resetAccount();
        }
    }
</script>