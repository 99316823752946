<template>
    <WidgetSection v-if="!hide" cssClass="content-type-7">
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_apps.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="apps">
                    <div class="app" v-for="(app, index) in apps" :key="index">
                        <AppLogo :app="app" />
                        <span>{{ app }}</span>
                    </div>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';
    import AppLogo from '@/components/elements/misc/AppLogo';

    export default {
        name: "ProspectApps",
        components: {
            WidgetSection,
            AppLogo
        },
        computed: {
            company() {
                return useProspectStore().prospect;
            },
            apps() {
                return this.company?.apps && this.company.apps != '' ? this.company.apps.split('|') : [];
            },
            hide() {
                return !this.apps || this.apps.length < 1;
            }
        }
    }
</script>