<template>
    <div :class="['container-fluid', 'content-type-15', cssClass]">
        <div class="container">
            <div class="row row-title">
                <div class="section-title">
                    <slot name="section-title"></slot>
                </div>
                <div class="section-actions">
                    <slot v-if="!$slots['section-noresults']" name="section-actions"></slot>
                </div>
            </div>

            <slot v-if="!loading" name="section-content"></slot>

            <div v-if="!loading && $slots['section-noresults']" class="row row-noresults">
                <div class="section-noresults">
                    <slot name="section-noresults"></slot>
                </div>
            </div>

            <div v-if="loading" class="row row-loading">
                <LoadingSection :showMessage="showMessage" :message="loadingText" />
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingSection from './LoadingSection';

    export default {
        name: "WidgetSection",
        components: {
           LoadingSection 
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            loadingText: {
                type: String,
                default: null
            },
            cssClass: {
                type: String,
                required: false
            }
        },
        computed: {
            showMessage() {
                return !!this.loadingText;
            }
        }
    }
</script>