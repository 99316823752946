<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <ProspectDetails v-if="prospect" />
        <ProspectActions
            v-if="hasNavigationButtons"
            @close="closeProspect"
            @previous="loadPreviousProspect"
            @next="loadNextProspect"
            :isFirstProspect="isFirstProspect"
            :isLastProspect="isLastProspect"
        />
    </AccountViewBase>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import { useProspectStore } from '@/stores/prospect';
    import { useProspectsStore } from '@/stores/prospects';
    import ProspectDetails from '@/components/account/prospect/ProspectDetails';
    import ProspectActions from '@/components/account/prospect/ProspectActions';

    export default {
        name: 'ProspectView',
        props: {
            id: {
                type: String,
                required: true
            }
        },
        components: {
            AccountViewBase,
            ProspectDetails,
            ProspectActions
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.prospects.seo_title'
            };
        },
        computed: {
            ...mapState(useProspectStore, {
                prospect: 'prospect',
                prospectLoading: 'loading'
            }),
            ...mapState(useProspectsStore, [
                'currentProspectIndex',
                'isFirstProspect',
                'isLastProspect'
            ]),
            loading() {
                return this.accountLoading || this.prospectLoading;
            },
            hasNavigationButtons() {
                return this.currentProspectIndex !== null;
            },
        },
        methods: {
            ...mapActions(useProspectStore, ['fetchProspect', 'resetProspect']),
            ...mapActions(useProspectsStore, ['loadNextProspect', 'loadPreviousProspect']),
            closeProspect() {
                this.$router.push({ name: "prospects" });
            }
        },
        watch: {
            '$route.params.id': {
                handler(newId) {
                    this.fetchProspect(newId);
                },
                immediate: true
            }
        },
        mounted() {
            this.fetchProspect(this.id);
        },
        unmounted() {
            this.resetProspect();
        }
    }
</script>
