<template>
    <div class="container-fluid content-type-10">
        <div class="container">
            <div class="row">
                <div class="col-6 col-lg-4 col-xl-3">
                    <ProspectFirmology />
                </div>
                <div class="col-6 col-lg-4 col-xl-3">
                    <ProspectContactInformation />                    
                </div>

                <div class="col-12 hidden-lg-up" v-if="socialMedia">
                    <ProspectSocialMedia @noContent="hide('socialMedia')" />
                </div>
                
                <div class="col-12 col-sm-6 hidden-lg-up" v-if="openingHours">
                    <ProspectOpeningHours @noContent="hide('openingHours')" />
                </div>
                
                <div class="col-12 col-sm-6 hidden-lg-up" v-if="reviews">
                    <ProspectReviewsSummary @noContent="hide('reviews')" />
                </div>

                <div class="col-4 col-xl-3 col-xl-offset-1 hidden-up visible-lg-up" v-if="socialMedia || openingHours || reviews">
                    <ProspectOpeningHours />
                    <ProspectReviewsSummary />
                    <ProspectSocialMedia />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProspectFirmology from '@/components/elements/prospect/ProspectFirmology';
    import ProspectContactInformation from '@/components/elements/prospect/ProspectContactInformation';
    import ProspectSocialMedia from '@/components/elements/prospect/ProspectSocialMedia';
    import ProspectReviewsSummary from '@/components/elements/prospect/ProspectReviewsSummary';
    import ProspectOpeningHours from '@/components/elements/prospect/ProspectOpeningHours';

    export default {
        name: "ProspectSpecs",
        components: {
            ProspectFirmology,
            ProspectContactInformation,
            ProspectSocialMedia,
            ProspectReviewsSummary,
            ProspectOpeningHours
        },
        data() {
            return {
                socialMedia: true,
                openingHours: true,
                reviews: true
            }
        },
        methods: {
            hide(name) {
                this[name] = false;
            }
        }
    }
</script>
