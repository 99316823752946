<template>
    <div class="container-fluid item-lists-type-2" v-if="!loading && !error">
        <div class="container">
            <UsersListItem
                v-for="user in users"
                :key="user.uid"
                :user="user"
            />
        </div>
    </div>
    <ErrorSection v-else-if="error" :error="error" />
    <LoadingSection v-else />
</template>

<script>
    import { mapState } from 'pinia';
    import { useUsersStore } from '@/stores/users';
    import UsersListItem from '@/components/account/users/UsersListItem';
    import ErrorSection from '@/components/sections/ErrorSection';
    import LoadingSection from '@/components/sections/LoadingSection';

    export default {
        name: 'UsersList',
        components: {
            UsersListItem,
            ErrorSection,
            LoadingSection
        },
        computed: {
            ...mapState(useUsersStore, ['users', 'loading', 'error']),
        }
    }
</script>