<template>
    <ProspectHeader :showLabel="true" />
    <ProspectSpecs />
    <div class="divider-1"></div>

    <ProspectTags />
    <ProspectPageViews />
    <ProspectContacts />
    <ProspectPeople />

    <ProspectFamily />
    <ProspectSameAddress />
    <ProspectProperty />
    <ProspectApps />

    <ProspectSiteSearch />
    <ProspectReviewsDetails />
    <ProspectNews />
    <ProspectVacancies />

    <ProspectSiteRank />
    <div class="divider-1"></div>
</template>

<script>
    import ProspectHeader from '@/components/elements/prospect/ProspectHeader';
    import ProspectSpecs from '@/components/elements/prospect/ProspectSpecs';
    import ProspectApps from '@/components/elements/prospect/ProspectApps';
    import ProspectReviewsDetails from '@/components/elements/prospect/ProspectReviewsDetails';
    import ProspectProperty from '@/components/elements/prospect/ProspectProperty';
    import ProspectSiteRank from '@/components/elements/prospect/ProspectSiteRank';
    import ProspectFamily from '@/components/elements/prospect/ProspectFamily';
    import ProspectSameAddress from '@/components/elements/prospect/ProspectSameAddress';
    import ProspectNews from '@/components/elements/prospect/ProspectNews';
    import ProspectSiteSearch from '@/components/elements/prospect/ProspectSiteSearch';
    import ProspectPeople from '@/components/elements/prospect/ProspectPeople';
    import ProspectContacts from '@/components/elements/prospect/ProspectContacts';
    import ProspectPageViews from '@/components/elements/prospect/ProspectPageViews';
    import ProspectVacancies from '@/components/elements/prospect/ProspectVacancies';
    import ProspectTags from '@/components/elements/prospect/ProspectTags';

    export default {
        name: "ProspectDetails",
        components: {
            ProspectHeader,
            ProspectSpecs,
            ProspectApps,
            ProspectReviewsDetails,
            ProspectPeople,
            ProspectContacts,
            ProspectPageViews,
            ProspectSiteRank,
            ProspectFamily,
            ProspectSameAddress,
            ProspectProperty,
            ProspectNews,
            ProspectSiteSearch,
            ProspectVacancies,
            ProspectTags
        }
    }
</script>
