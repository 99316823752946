<template>
  <nav :class="navClass">
    <ul>
      <CustomMenuItem v-for="(link, index) in links" :key="index + '-' + uniqueId" :link="link" />
    </ul>
  </nav>
</template>

<script>
import CustomMenuItem from './CustomMenuItem.vue';

export default {
  name: 'CustomMenu',
  props: {
    navClass: {
      type: String,
      default: 'nav-menu',
    },
    links: {
      type: Array,
      required: true,
    },
  },
  components: {
    CustomMenuItem,
  },
  computed: {
      uniqueId() {
          return Math.random().toString(36).substr(2, 9);
      }
  }
};
</script>