<template>
    <div class="labels-type-1">
        <div v-if="label === 1" class="label label-pos">
            <div class="icon-target-neg"></div>
            <!-- <span>Doelgroep</span> -->
        </div>
        <div v-else-if="label === 0" class="label label-neg">
            <div class="icon-close-neg"></div>
            <!-- <span>Geen doelgroep</span> -->
        </div>
        <div v-else class="label label-neutral">
            <div class="icon-new-neg"></div>
            <!-- <span>Nieuw</span> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "LabelMini",
    props: {
        label: {
            type: Number,
            required: true
        }
    }
}
</script>