<template>
    <SettingsSection cssClass="account-tracker-settings">
        <template #title>
            <h2>{{ $t('views.account_settings.account_tracker_settings_title') }}</h2>
        </template>

        <template #content>
            <CopyPasteCode :code="account.tracker_script" />
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/account/settings/Section';
    import CopyPasteCode from '@/components/elements/misc/CopyPasteCode';
    
    export default {
        name: 'AccountTrackerSettings',
        components: {
            SettingsSection,
            CopyPasteCode
        },
        props: {
            account: {
                type: Object,
                required: true
            }
        }
    }
</script>