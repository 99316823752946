<template>
    <div :class="['options-type-1-button', cssClass]">
        <div class="toggle-btn">
            <div class="icon-search-neg icon-xs"></div>
            <span>{{ $t('common.search_filter_sort') }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OptionsPanelButton",
        props: {
            cssClass: {
                type: String,
                required: false
            }
        }
    }
</script>