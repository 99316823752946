<template>
    <SettingsSection cssClass="profile-gravatar-settings">
        <template #title>
            <h2>{{$t('views.profile.gravatar_settings_title')}}</h2>
        </template>

        <template #content>
            <a class="gravatar" href="https://gravatar.com/" target="_blank">
                <div class="gravatar-image">
                    <GravatarImage :email="userStore.user.email" />
                </div>
                <div class="text">{{ $t('views.profile.gravatar_link_text')}}</div>
            </a>
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/profile/Section';
    import { useUserStore } from '@/stores/user';
    import GravatarImage from '../elements/misc/GravatarImage';
    
    export default {
        name: 'ProfileGravatarSettings',
        components: {
            SettingsSection,
            GravatarImage
        },
        computed: {
            userStore() {
                return useUserStore();
            }
        }
    }
</script>