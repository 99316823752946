<template>
    <div v-if="userStore.hasUserDetails" ref="profileNav" :class="['profile-nav-type-1', { open: navExpanded }]" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
        <div class="nav-profile-button" @click="handleClick">
            <div class="left">
                <div class="title">{{ userStore.user.name ? $t('common.welcome') + ' ' + userStore.user.name : $t('common.welcome') }}</div>
                <div class="description">{{ userStore.user.email }}</div>
            </div>
            <div class="right">
                <div class="image">
                    <GravatarImage :email="userStore.user.email" />
                </div>
            </div>
        </div>

        <div v-if="navExpanded" class="container expand-navigation">
            <CustomMenu navClass="nav-menu-profile" :links="navLinks" />
        </div>
    </div>
</template>

<script>
import { useUserStore } from '@/stores/user';
import CustomMenu from '@/components/elements/navigation/CustomMenu';
import GravatarImage from '@/components/elements/misc/GravatarImage';

export default {
    name: 'ProfileNav',
    components: {
        CustomMenu,
        GravatarImage
    },
    computed: {
        userStore() {
            return useUserStore();
        },
    },
    data() {
        return {
            navExpanded: false,
            navLinks: [
                {
                    route: { name: 'accounts' },
                    text: 'links.accounts',
                    icon: 'company'
                },
                {
                    route: { name: 'profile' },
                    text: 'links.profile',
                    icon: 'account'
                },
                {
                    url: "https://www.prospectpro.nl/klantenservice/",
                    text: 'links.customer_service',
                    icon: 'information'
                },
                {
                    function: this.logout,
                    text: 'links.signout',
                    icon: 'signout',
                    class: 'danger'
                }
            ],
            isTouchDevice: false,
        }
    },
    methods: {
        logout() {
            this.userStore.logout();
        },
        toggleExpand(show = null) {
            this.navExpanded = typeof(show) === 'boolean' ? show : !this.navExpanded;
        },
        handleClick() {
            if (this.isTouchDevice) {
                this.toggleExpand();
            }
        },
        handleMouseOver() {
            if (!this.isTouchDevice) {
                this.toggleExpand(true);
            }
        },
        handleMouseLeave() {
            if (!this.isTouchDevice) {
                this.toggleExpand(false);
            }
        },
        handleClickOutside(event) {
            if (this.isTouchDevice && this.navExpanded && !this.$refs.profileNav.contains(event.target)) {
                this.toggleExpand(false);
            }
        },
        checkTouchDevice() {
            this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    },
    watch: {
        $route() {
            this.toggleExpand(false);
        }
    },
    beforeMount() {
        this.checkTouchDevice();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
        this.toggleExpand(false);
    }
}
</script>
