<template>
    <WidgetSection :loading="loading" loadingText="components.prospect_pageviews.loading_text" v-if="!hide">
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_pageviews.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="table-container">
                    <table class="table-type-1">
                        <thead>
                            <tr class="table-header">
                                <td>{{ $t('common.page_title') }}</td>
                                <td>{{ $t('common.last_visit') }}</td>
                                <td>{{ $t('common.language') }}</td>
                                <td>{{ $t('common.medium') }}</td>
                                <td>{{ $t('common.source') }}</td>
                                <td>{{ $t('common.device_type') }}</td>
                                <td>{{ $t('common.platform') }}</td>
                                <td>{{ $t('common.browser') }}</td>
                                <td>{{ $t('common.referer') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="company" v-for="pageview in pageviews" :key="pageview.last_visit">
                                <td>
                                    <a :href="pageview.page_url.split('?')[0]" target="_blank" rel="noreferrer external">{{ pageview.page_title }}</a>
                                </td>
                                <td>{{ pageview.last_visit }}</td>
                                <td>{{ pageSource?.language ? pageSource.language : '-' }}</td>
                                <td>{{ pageSource?.medium ? pageSource.medium : '-' }}</td>
                                <td>{{ pageSource?.source ? pageSource.source : '-' }}</td>
                                <td>{{ pageSource?.device_type ? pageSource.device_type : '-' }}</td>
                                <td>{{ pageSource?.platform ? pageSource.platform : '-' }}</td>
                                <td>{{ pageSource?.browser ? pageSource.browser : '-' }}</td>
                                <td>{{ pageSource?.referer ? pageSource.referer : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';

    export default {
        name: "ProspectPageviews",
        components: {
            WidgetSection
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {
            hide() {
                return !this.loading && (!this.pageviews || this.pageviews.length < 1);
            },
            pageviews() {
                return useProspectStore().pageviews;
            },
            pageSource() {
                return useProspectStore().page_source;
            }
        },
        methods: {
            async fetchPageviews() {
                this.loading = true;
                await useProspectStore().fetchPageviews();
                this.loading = false;
            },
        },
        mounted() {
            this.fetchPageviews();
        }
    }
</script>