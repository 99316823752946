<template>
    <div :class="['hamburger-button', { open: modelValue }]" @click="toggleHamburger">
        <div class="hamburger-icon">
            <div class="burger"></div>
            <div class="ham"></div>
            <div class="burger"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HamburgerButton',
        props: {
            modelValue: {
                type: Boolean
            }
        },
        methods: {
            toggleHamburger() {
                this.$emit('update:modelValue', !this.modelValue);
            },
        }
    };
</script>