<template>
    <div v-if="company" class="container-fluid content-type-4">
        <div class="container">
            <div class="row">
                <div class="left">
                    <div class="logo">
                        <CompanyLogo 
                            :domain="company.domain ? company.domain : null"
                            :company_name="company.name ? company.name : company.domain ? company.domain : null"
                        />
                    </div>
                </div>
                <div class="center" v-if="showLabel">
                    <ProspectLabel />
                </div>
                <div class="right">
                    <div class="name">{{ company.name }}</div>
                    <div class="location">{{ company.city }}{{ company.city && company.country_code ? ', ' : '' }}<span class="country">{{ company.country_code }}</span></div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="company" class="content-type-4-spacer"></div>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';
    import ProspectLabel from '@/components/elements/prospect/ProspectLabel';

    export default {
        name: "ProspectHeader",
        components: {
            CompanyLogo,
            ProspectLabel
        },
        props: {
            showLabel: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            company() {
                return useProspectStore().prospect;
            }
        }
    }
</script>