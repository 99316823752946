<template>
  <div class="toast-type-1">
    <div 
    v-for="toast in toastStore.toasts" 
    :key="toast.id" 
    :class="['toast', toast.type]"
    @click="removeToast(toast.id)">
        <div class="icon">
            <div v-if="toast.type === 'success'" class="icon-check-pos"></div>
            <div v-else-if="toast.type === 'error'" class="icon-close-pos"></div>
            <div v-else-if="toast.type === 'neutral'" class="icon-information-pos"></div>
            <div v-else-if="toast.type" :class="`icon-${ toast.type }-pos`"></div>
            <div v-else class="icon-check-pos"></div>
        </div>
        <div class="message">
            {{ toast.message }}
        </div>
        <div class="close">
            <div class="icon-close-pos"></div>
        </div>
    </div>
  </div>
</template>

<script>
    import { useToastStore } from '@/stores/toast';

    export default {
    name: 'ToastNotifications',
    computed: {
        toastStore() {
            return useToastStore();
        }
    },
    methods: {
        removeToast(id) {
            this.toastStore.removeToast(id);
        }
    }
    };
</script>