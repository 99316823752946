export const socialMap = [
    {
        text: "LinkedIn",
        value: "linkedin",
        icon: "linkedin",
        color: "0077B5"
    },
    {
        text: "Facebook",
        value: "facebook",
        icon: "facebook",
        color: "4267B2"
    },
    {
        text: "X (Twitter)",
        value: "twitter",
        icon: "x",
        color: "000000"
    },
    {
        text: "Instagram",
        value: "instagram",
        icon: "instagram",
        color: "E1306C"
    },
    {
        text: "YouTube",
        value: "youtube",
        icon: "youtube",
        color: "FF0000"
    },
    {
        text: "Pinterest",
        value: "pinterest",
        icon: "pinterest",
        color: "E60023"
    }
];