export default {
    watch: {
      '$i18n.locale'() {
        this.updateDocumentTitle();
      }
    },
    methods: {
      updateDocumentTitle() {
        if (this.seo_title) {
          document.title = this.$t(this.seo_title);
        }
      }
    },
    mounted() {
      this.updateDocumentTitle();
    }
};
