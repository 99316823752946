<template>
  <li>
    <template v-if="link.route">
      <router-link
        :to="link.route"
        :class="[link.class ? link.class : '', { 'current-route': isCurrentRoute }, { 'active-route': isActiveRoute }]"
      >
        <div v-if="link.icon" :class="['link-icon', `icon-${link.icon}-pos`]"></div>
        <div v-if="link.text" class="link-text">{{ $t(link.text) }}</div>
      </router-link>
    </template>

    <template v-else-if="link.function">
      <a @click="link.function" :class="link.class ? link.class: ''">
        <div v-if="link.icon" :class="['link-icon', `icon-${link.icon}-pos`]"></div>
        <div v-if="link.text" class="link-text">{{ $t(link.text) }}</div>
      </a>
    </template>

    <template v-else-if="link.url">
      <a :href="link.url" target="_blank" :class="link.class ? link.class: ''">
        <div v-if="link.icon" :class="['link-icon', `icon-${link.icon}-pos`]"></div>
        <div v-if="link.text" class="link-text">{{ $t(link.text) }}</div>
      </a>
    </template>

    <template v-else-if="link.children">
        <div :class="link.class ? link.class: ''">
            <div v-if="link.icon" :class="['link-icon', `icon-${link.icon}-pos`]"></div>
            <div v-if="link.text" class="link-text">{{ $t(link.text) }}</div>
        </div>
        <ul class="sub-menu">
        <CustomMenuItem v-for="(child, index) in link.children" :key="index" :link="child" />
        </ul>
    </template>
    
  </li>
</template>

<script>
export default {
  name: 'CustomMenuItem',
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCurrentRoute() {
        const targetPath = this.resolveTargetPath();
        return targetPath ? this.$route.path === targetPath : false;
    },
    isActiveRoute() {
        const targetPath = this.resolveTargetPath();
        return targetPath ? this.$route.path.startsWith(targetPath) : false;
    },
  },
  methods: {
    resolveTargetPath() {
        let targetPath;
        if (typeof this.link.route === 'object' && this.link.route.name) {
            const resolvedRoute = this.$router.resolve(this.link.route);
            if (resolvedRoute && resolvedRoute.path) {
                targetPath = resolvedRoute.path;
            } else {
                return null;
            }
        } else if (typeof this.link.route === 'string') {
            targetPath = this.link.route;
        } else {
            return null;
        }

        return targetPath;
    },
  },
  components: {
    CustomMenuItem: () => import('@/components/elements/navigation/CustomMenuItem'),
  },
};
</script>
