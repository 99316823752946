<template>
    <ToggleSimple>
        <template #toggle>
            <div class="left">
                <div v-if="$te('fields.location.label')" class="toggle-text">
                    {{ $t('fields.location.label') }}
                    <span v-if="countProvinces.selected && countProvinces.selected > 0">{{ countProvinces.selected }}</span>
                    <span v-else-if="modelValue.city && modelValue.city !== ''">
                        <div class="icon-check-dark"></div>
                    </span>
                </div>
            </div>
            <div class="right">
                <div v-if="isActive" class="reset" @click="resetSection">
                    <div class="icon-reset-pos"></div>
                </div>
                <div class="toggle-icon"></div>
            </div>
        </template>

        <template #content>
            <div v-if="!mode || mode === 'city'" class="location-city">
                <div :class="['autosuggest-type-1', suggestions.length > 0 ? 'active' : '']">
                    <div class="autosuggest-input">
                        <CustomInput
                            :modelValue="modelValue.city"
                            inputKey="city"
                            @update:modelValue="onChanged"
                            @keydown.down.prevent="highlightNext"
                            @keydown.up.prevent="highlightPrevious"
                            @keydown.enter.prevent="selectHighlighted"
                        />

                        <div class="autosuggest-options" v-if="suggestions.length > 0" ref="suggestionsList">
                            <ul @click.stop>
                                <li 
                                    v-for="(suggestion, index) in suggestions" 
                                    :key="suggestion.term" 
                                    :class="{ 'highlighted': index === highlightedIndex }"
                                    @click="selectSuggestion(suggestion.term)"
                                >
                                    {{ suggestion.term }}
                                </li>
                            </ul> 
                        </div>
                    </div>
                </div>

                <CustomSelect
                    v-if="mode === 'city'"
                    :modelValue="modelValue.radius"
                    inputKey="radius"
                    @update:modelValue="onRadiusChanged"
                    :options="optionsRadius"
                />
            </div>

            <div v-if="!mode || mode === 'province'" :class="['location-province', mode === 'province' ? 'active' : '']">
                <CustomCheckbox
                    :modelValue="modelValue.province"
                    inputKey="province"
                    @update:modelValue="onProvinceChanged"
                    :options="optionsProvince"
                    :cssClass="cssClass"
                />
            </div>
        </template>
    </ToggleSimple>
</template>

<script>
    import { debounce } from 'lodash';
    import { fetchSuggestions } from '@/services/ppApi';
    import { useAccountStore } from '@/stores/account';
    import { provinceMap } from '@/utils/maps/provinceMap';
    import i18n from "@/plugins/i18n";
    import ToggleSimple from '@/components/elements/misc/ToggleSimple';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CustomCheckbox from '@/components/elements/inputs/CustomCheckbox';
    import CustomSelect from '@/components/elements/inputs/CustomSelect';

    export default {
        name: "OptionsLocation",
        components: {
            ToggleSimple,
            CustomInput,
            CustomCheckbox,
            CustomSelect
        },
        data() {
            return {
                suggestions: [],
                highlightedIndex: -1,
            }
        },
        props: {
            modelValue: {
                type: Object,
                default: () => {}
            },
            cssClass: {
                type: String,
                default: 'custom-checkbox-type-5'
            }
        },
        computed: {
            isActive() {
                return !!this.modelValue.city || !!this.modelValue.radius || this.modelValue.province.length > 0;
            },
            mode() {
                if (this.modelValue.province.length > 0) {
                    return 'province';
                } else if (this.modelValue.city !== '') {
                    return 'city';
                } else {
                    return false;
                }
            },
            optionsProvince() {
                return provinceMap.map(option => ({
                    noTranslate: true,
                    text: option.text,
                    value: option.value,
                    icon: option.icon,
                    color: option.color
                }));
            },
            optionsRadius() {
                const distances = [0, 1, 2, 3, 4, 5, 10, 15, 25, 50];
                return distances.map(option => ({
                    noTranslate: true,
                    text: option === 0 ? i18n.global.t('fields.radius.option_all') : '+' + option + ' ' + i18n.global.t('fields.radius.option_suffix'),
                    value: option
                }));
            },
            countProvinces() {
                return {
                    total: this.optionsProvince.length,
                    selected: this.modelValue.province.length,
                    not_selected: this.optionsProvince.length - this.modelValue.province.length
                }
            }
        },
        methods: {
            resetSection(event = null) {
                if (event) {
                    event.stopPropagation()
                }
                this.$emit('update:modelValue', { 
                    province: [],
                    city: '',
                    radius: null,
                });
            },
            onChanged: debounce(function(newValue) {
                this.search = newValue;
                this.getSuggestions(newValue);
                this.selectSuggestion(newValue);
            }, 300),
            async getSuggestions(newValue) {
                if(newValue.length < 1 || newValue.length > 10) {
                    this.suggestions = [];
                    return;
                }
                try {
                    const accountStore = useAccountStore();
                    const response = await fetchSuggestions({
                        account_id: accountStore.account.account_id,
                        type: 'city',
                        q: newValue
                    });
                    this.suggestions = response.data.suggest;
                } catch {
                    this.suggestions =  [];
                } finally {
                    this.highlightedIndex = -1;
                }
            },
            selectSuggestion(term) {
                this.$emit('update:modelValue', { ...this.modelValue, city: term });
                this.closeSuggestionList();
            },
            onProvinceChanged(newProvince) {
                this.$emit('update:modelValue', { ...this.modelValue, province: newProvince });
            },
            onRadiusChanged(newRadius) {
                this.$emit('update:modelValue', { ...this.modelValue, radius: newRadius });
            },
            closeSuggestionList() {
                if (this.$refs.suggestionsList) {
                    this.suggestions = [];
                    this.highlightedIndex = -1;
                }
            },
            highlightNext() {
                if (this.highlightedIndex < this.suggestions.length - 1) {
                    this.highlightedIndex++;
                }
            },
            highlightPrevious() {
                if (this.highlightedIndex > 0) {
                    this.highlightedIndex--;
                }
            },
            selectHighlighted() {
                if (this.highlightedIndex >= 0 && this.highlightedIndex < this.suggestions.length) {
                    this.selectSuggestion(this.suggestions[this.highlightedIndex].term);
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.closeSuggestionList);
        },
        unmounted() {
            document.removeEventListener('click', this.closeSuggestionList);
        },
    }
</script>