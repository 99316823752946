<template>
    <div :class="['container-fluid', 'content-type-5', 'suspect', cssClass]">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div :class="['btn-s-icon', 'suspect-previous', history && history.length > 0 ? 'btn-5' : 'btn-disabled']" @click="previousSuspect" v-if="showSuspectActions">
                            <div class="icon-chevrons-pos"></div>
                        </div>
                        <div class="btn-7 btn-m-icon suspect-qualify" @click="qualifySuspect(1)" v-if="showSuspectActions">
                            <div class="icon-check-neg"></div>
                        </div>
                        <div class="btn-1 btn-m-icon suspect-settings" @click="openSettings" v-if="!showSuspectOptions">
                            <div v-if="!showSuspectOptions" class="icon-settings-neg"></div>
                        </div>
                        <div class="btn-1 btn-m-icon suspect-settings" @click="saveSettings" v-if="showSuspectOptions && optionsChanged">
                            <div v-if="showSuspectOptions" class="icon-search-neg"></div>
                        </div>
                        <div class="btn-1 btn-m-icon suspect-settings" @click="saveSettings" v-if="showSuspectOptions && !optionsChanged">
                            <div v-if="showSuspectOptions" class="icon-close-neg"></div>
                        </div>
                        <div class="btn-6 btn-m-icon suspect-disqualify" @click="qualifySuspect(0)" v-if="showSuspectActions">
                            <div class="icon-close-neg"></div>
                        </div>
                        <div class="btn-5 btn-s-icon suspect-skip" @click="qualifySuspect(-1)" v-if="showSuspectActions">
                            <div class="icon-chevrons-pos"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-type-5-spacer suspect"></div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useSuspectStore } from '@/stores/suspect';
    import { useProspectStore } from '@/stores/prospect';

    export default {
        name: "SuspectActions",
        emits: ['toggle-options'],
        props: {
            cssClass: {
                type: String,
                required: false
            },
            showSuspectOptions: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState(useSuspectStore, ['history', 'loading', 'error', 'optionsChanged']),
            ...mapState(useProspectStore, ['prospect']),
            showSuspectActions() {
                return !this.loading && !this.error && !this.showSuspectOptions && this.prospect;
            }
        },
        methods: {
            ...mapActions(useSuspectStore, ['fetchSuspect', 'qualifySuspect', 'previousSuspect']),
            openSettings() {
                this.$emit('toggle-options');
            },
            saveSettings() {
                if( this.optionsChanged ) {
                    this.fetchSuspect();
                }
                this.$emit('toggle-options');
            }
        },
    }
</script>