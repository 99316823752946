<template>
    <SettingsSection cssClass="account-details-settings">
        <template #title>
            <h2>{{ $t('views.account_settings.account_details_settings_title') }}</h2>
        </template>

        <template #content>
            <AccountDetailsForm :hideSaveButton="true" />
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/account/settings/Section';
    import AccountDetailsForm from '@/components/forms/AccountDetailsForm';
    
    export default {
        name: 'ProfilePersonaliaSettings',
        components: {
            SettingsSection,
            AccountDetailsForm
        }
    }
</script>