<template>
    <div :class="['row', 'account', currentStatus]" @click="selectAccount">
        <div class="item-image">
            <CompanyLogo 
                :domain="account.domain ? account.domain : null"
                :company_name="account.name ? account.name : account.domain ? account.domain : null"
            />
        </div>

        <div class="content">
            <div class="account-name">{{ account.name || '-' }}</div>
            <p class="account-url">{{ account.url }}</p>
        </div>

        <div class="actions">
            <div class="btn-1 btn-s-icon">
                <div class="icon-arrow-neg"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'pinia';
    import { useAccountStore } from '@/stores/account';
    import router from '@/router';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';

    export default {
        name: 'AccountsListItem',
        components: {
            CompanyLogo
        },
        props: {
            account: {
                type: Object,
                required: true,
            },
        },
        computed: {
            currentStatus() {
                switch (this.account.status) {
                    case -1:
                        return 'deactivated';
                    case 0:
                        return 'inactive';
                    case 1:
                    default:
                        return 'active';
                }
            }
        },
        methods: {
            ...mapActions(useAccountStore, ['setAccount']),
            selectAccount() {
                this.setAccount({ account_id: this.account.account_id });
                router.push({ name: 'suspect' });
            }
        },
    };
</script>