<template>
    <template v-if="apiKey">
        <CopyPasteCode :code="apiKey" />
        <div class="btn-m btn-1" @click="hideApiKey">
            {{ $t('components.api_key_manager.button_hide') }}
        </div>
        <div class="btn-m btn-6" @click="deleteApiKeyPopup">
            {{ $t('components.api_key_manager.button_delete') }}
        </div>
    </template>
    <template v-else>
        <div class="btn-m btn-1" @click="showApiKey">
            <div v-if="loading" class="loading-spinner-type-1"></div>
            <span v-else>
                {{ $t(buttonText) }}
            </span>
        </div>
    </template>
</template>

<script>
    import CopyPasteCode from '@/components/elements/misc/CopyPasteCode';
    import { useAccountStore } from '@/stores/account';
    import { usePopupStore } from '@/stores/popup';

    export default {
    name: 'ApiKeyManager',
    components: {
        CopyPasteCode
    },
    props: {
        apiKeyExists: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data() {
        return {
            apiKey: null,
            loading: false,
            hasApiKey: this.apiKeyExists
        };
    },
    watch: {
        hasApiKey(newVal) {
            this.accountStore.setApiKeyStatus(newVal ? 1 : 0);
        }
    },
    computed: {
        accountStore() {
            return useAccountStore();
        },
        popupStore() {
            return usePopupStore();
        },
        buttonText() {
            return this.apiKey ? 'components.api_key_manager.button_hide' : this.hasApiKey ? 'components.api_key_manager.button_show' : 'components.api_key_manager.button_create';
        }
    },
    methods: {
        async showApiKey() {
            this.loading = true;
            try {
                this.hasApiKey = true;
                this.apiKey = await this.accountStore.fetchAccountApiKey();
            } catch (error) {
                this.apiKey = null;
            } finally {
                this.loading = false;
            }
        },
        async deleteApiKey() {
            this.loading = true;
            try {
                    const response = await this.accountStore.deleteAccountApiKey();
                    if (response.status === 200) {
                        this.apiKey = null;
                        this.hasApiKey = false;
                        return true;
                    } else {
                        return false;
                    }
            } catch (error) {
                return false;
            } finally {
                this.loading = false;
            }
        },
        hideApiKey() {
            this.apiKey = null;
        },
        deleteApiKeyPopup() {
            this.popupStore.showPopup('confirm', {
                title: 'popups.confirm_delete_api_key.title',
                description: 'popups.confirm_delete_api_key.description',
                btnConfirm: "components.api_key_manager.button_delete",
                btnCancel: "common.cancel_1",
                danger: true,
                confirmFunction: this.deleteApiKey
            });
        },
    }
    };
</script>