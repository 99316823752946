<template>
    <div v-if="sources?.length > 0" class="reviews-type-1">
        <StarsReview v-if="combinedSources.rating" :score="combinedSources.rating" />

        <ul class="sources">
            <li v-for="source in sources" :key="source.id" class="source">
                <CompanyLogo
                    :domain="source.domain ? source.domain : null"
                    :company_name="source.name ? source.name : source.domain ? source.domain : null"
                />
                <StarsReview :score="source.rating" :total="source.reviews" :showStars="false" />
            </li>
        </ul>
    </div>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';
    import StarsReview from '@/components/elements/misc/StarsReview';

    export default {
        name: "ProspectReviewsSummary",
        emits: ['noContent'],
        components: {
            CompanyLogo,
            StarsReview
        },
        computed: {
            sources() {
                return useProspectStore().reviewsSummary;
            },
            combinedSources() {
                if (!this.sources.length) return { totalReviews: 0, weightedRating: 0 };

                let totalReviews = 0;
                let weightedSum = 0;

                this.sources.forEach(({ rating, reviews }) => {
                    const reviewCount = parseInt(reviews, 10);
                    totalReviews += reviewCount;
                    weightedSum += parseFloat(rating) * reviewCount;
                });

                const weightedRating = totalReviews ? (weightedSum / totalReviews).toFixed(1) : 0;

                return {
                    rating: weightedRating,
                    reviews: totalReviews
                };
            },
        },
        mounted() {
            if(
                !this.sources?.length > 0
            ) {
                this.$emit('noContent');
            }
        }
    }
</script>