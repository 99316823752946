<template>
    <TitleSection cssClass="account-settings-title">
        <h1>{{ $t('views.account_settings.title') }}</h1>
        <p>{{ $t('views.account_settings.description') }}</p>
    </TitleSection>
</template>

<script>
    import TitleSection from '@/components/account/settings/Title';
    
    export default {
        name: 'AccountSettingsTitle',
        components: {
            TitleSection
        }
    }
</script>