<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <UserHeader />
        <UsersList />
    </AccountViewBase>
</template>

<script>
    import { useUsersStore } from '@/stores/users';
    import AccountViewBase from '@/views/account/AccountViewBase';
    import accountMixin from '@/mixins/accountMixin';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import UserHeader from '@/components/account/users/UserHeader';
    import UsersList from '@/components/account/users/UsersList';

    export default {
        name: 'AccountUsersView',
        components: {
            AccountViewBase,
            UserHeader,
            UsersList
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.users.seo_title',
            };
        },
        computed: {
            usersStore() {
                return useUsersStore();
            }
        },
        methods: {
            getUsers() {
                return this.usersStore.fetchUsers(this.accountStore.account.account_id);
            }
        },
        mounted() {
            this.getUsers();
        }
    }
</script>
