<template>
    <template v-if="mainNav.showNav">
        <div 
            :class="['nav-type-2', { open: navExpanded }]"
            @mouseover="handleMouseOver" 
            @mouseleave="handleMouseLeave"
            ref="sideNav"
        >
            <div class="container">
                <div class="nav-top">
                    <div class="nav-logo-container">
                        <router-link :to="{ name: 'suspect' }">
                            <div class="nav-logo logo-icon-pos"></div>
                        </router-link>
                    </div>
                    
                    <ProfileLink />
                </div>

                <div class="nav-center">
                    <CustomMenu class="nav-menu-main" :links="navLinks" />
                </div>

                <div class="nav-bottom">
                    <CustomMenu class="nav-menu-auth" :links="navLinksAuth" />
                </div>

                <div 
                    class="nav-toggle-button" 
                    @click="handleClick"
                    v-if="isTouchDevice"
                    ref="toggleButton"
                >
                    <div class="icon-arrow-neg"></div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
    import { useUserStore } from '@/stores/user';
    import CustomMenu from '@/components/elements/navigation/CustomMenu';
    import ProfileLink from '@/components/elements/navigation/ProfileLink';
    import { useMainNavStore } from '@/stores/mainNav';

    export default {
        name: 'SideNav',
        components: {
            CustomMenu,
            ProfileLink
        },
        data() {
            return {
                navExpanded: false,
                isTouchDevice: false,
                navLinks: [
                    {
                        route: { name: 'suspect' },
                        text: 'links.suspect',
                        icon: 'cards'
                    },
                    {
                        route: { name: 'prospects' },
                        text: 'links.prospects',
                        icon: 'target'
                    },
                ],
                navLinksAuth: [
                    {
                        route: { name: 'settings' },
                        text: 'links.settings',
                        icon: 'settings'
                    },
                    {
                        route: { name: 'users' },
                        text: 'links.users',
                        icon: 'team'
                    },
                    {
                        url: "https://www.prospectpro.nl/klantenservice/",
                        text: 'links.customer_service',
                        icon: 'information'
                    },
                    {
                        function: this.logout,
                        text: 'links.signout',
                        icon: 'signout',
                        class: 'danger'
                    }
                ],
            }
        },
        computed: {
            userStore() {
                return useUserStore();
            },
            mainNav() {
                return useMainNavStore();
            }
        },
        methods: {
            toggleExpand(show = null) {
                this.navExpanded = typeof(show) === 'boolean' ? show : !this.navExpanded;
            },
            handleMouseOver() {
                if (!this.isTouchDevice) {
                    this.toggleExpand(true);
                }
            },
            handleMouseLeave() {
                if (!this.isTouchDevice) {
                    this.toggleExpand(false);
                }
            },
            handleClick() {
                if (this.isTouchDevice) {
                    this.toggleExpand();
                }
            },
            handleClickOutside(event) {
                if (this.isTouchDevice && this.navExpanded && !this.$refs.sideNav.contains(event.target)) {
                    this.toggleExpand(false);
                }
            },
            logout() {
                this.userStore.logout();
            },
            checkTouchDevice() {
                this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
            }
        },
        watch: {
            $route() {
                this.toggleExpand(false);
            }
        },
        beforeMount() {
            this.checkTouchDevice();
            document.addEventListener('click', this.handleClickOutside);
        },
        beforeUnmount() {
            document.removeEventListener('click', this.handleClickOutside);
            this.toggleExpand(false);
        },
    }
</script>