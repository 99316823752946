import { useUserStore } from '@/stores/user';
import { useAccountStore } from '@/stores/account';

export function ifNotAuthenticatedRedirect(to, from, next) {
  const userStore = useUserStore();

  if (!userStore.isAuthenticated) {
    console.log(to.fullPath);
    userStore.setRedirectAfterLogin(to.fullPath); 
    next({ name: 'login' });
  } else {
    next();
  }
}

export function ifAuthenticatedRedirect(to, from, next) {
    const userStore = useUserStore();
  
    if (userStore.isAuthenticated) {
      next({ name: 'home' });
    } else {
      next();
    }
}

export function hasQueryParamPasswordSelect(to, from, next) {
    if (!to.query.r) {
      next({ name: 'passwordReset' });
    } else {
      next();
    }
}

export async function hasAccountId(to, from, next) {
    const accountStore = useAccountStore();
    const accountId = to.query.account_id;
  
    if (accountId) {
      accountStore.changeAccount(accountId);
    }

    if (accountStore.hasAccountId) {
        next();
    } else {
        next({ name: 'accounts' });
    }
}