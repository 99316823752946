<template>
    <div class="form-type-0">
        <form @submit.prevent="login" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="email"
                inputKey="email"
                type="email"
                ref="email"
                autocomplete="email"
                :disabled="confirmAuth"
                required
            />
            <CustomInput
                v-model="password"
                inputKey="password"
                type="password"
                ref="password"
                autocomplete="current-password"
                required
            />         
            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t( confirmAuth ? 'forms.confirm_auth.button' : 'forms.login.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t( confirmAuth ? 'forms.confirm_auth.error' : 'forms.login.error') }}</p>
        </form>
    </div>
</template>

<script>
    import { useUserStore } from '@/stores/user';
    import { useAccountStore } from '@/stores/account';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import formMixin from '@/mixins/formMixin';

    export default {
        mixins: [formMixin],
        props: {
            confirmAuth: {
                type: Boolean,
                default: false
            }
        },
        emits: ['form_submission_successful'],
        components: {
            CustomInput
        },
        data() {
            return {
                email: '',
                password: ''
            };
        },
        computed: {
            userStore() {
                return useUserStore();
            },
            accountStore() {
                return useAccountStore();
            }
        },
        created() {
            if(this.confirmAuth) {
                if(this.userStore.user && this.userStore.user.email) {
                    this.email = this.userStore.user.email;
                } else {
                    this.userStore.logout();
                }
            }
        },
        methods: {
            async login() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;
                    const success = await this.userStore.login(this.email, this.password);
                    this.loading = false;
                    
                    if (success) {
                        form.reset();
                        this.formError = false;

                        if(!this.confirmAuth) {
                            this.userStore.redirectUserAfterLogin();
                        } else {
                            this.$emit('form_submission_successful');
                        }
                    } else {
                        this.formError = true;
                    }
                } else {
                    this.validateAllFields();
                }
            }
        }
    }
</script>