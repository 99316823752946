<template>
    <div :class="['row', 'section', cssClass]">
        <div class="col-12 col-md-10 col-md-offset-1 col-xl-3 col-xl-offset-1">
            <div class="title">
                <slot name="title"></slot>
            </div>
        </div>
        <div class="col-12 col-md-10 col-md-offset-1 col-xl-6 col-xl-offset-0">
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
            name: 'AccountSettingsSection',
            props: {
                cssClass: {
                    type: String
                },
            }
    }
</script>