<template>
    <WidgetSection :loading="loading" loadingText="common.loading_1" v-if="!hide">
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_property.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <ul class="specs-type-1">
                    <li v-if="property.details.gebruiksdoel">
                        <div class="title">
                            {{ $t('components.prospect_property.type')}}
                        </div>
                        <div class="content capitalize">
                            {{ property.details.gebruiksdoel }}
                        </div>
                    </li>
                    <li v-if="property.details.energielabel">
                        <div class="title">
                            {{ $t('components.prospect_property.energy_label')}}
                        </div>
                        <div class="content">
                            {{ property.details.energielabel }}
                        </div>
                    </li>
                    <li v-if="property.details.bouwjaar">
                        <div class="title">
                            {{ $t('components.prospect_property.year_construction')}}
                        </div>
                        <div class="content">
                            {{ property.details.bouwjaar }}
                        </div>
                    </li>
                    <li v-if="property.details.oppervlakte">
                        <div class="title">
                            {{ $t('components.prospect_property.size_floor')}}
                        </div>
                        <div class="content">
                            {{ property.details.oppervlakte }} m²
                        </div>
                    </li>
                    <li v-if="property.details.grondoppervlakte">
                        <div class="title">
                            {{ $t('components.prospect_property.size_land')}}
                        </div>
                        <div class="content">
                            {{ property.details.grondoppervlakte }} m²
                        </div>
                    </li>
                    <li v-if="property.details.woz">
                        <div class="title">
                            {{ $t('components.prospect_property.woz')}}
                        </div>
                        <div class="content">
                            {{ property.details.woz }} <span v-if="property.details.woz_year" class="woz-year">({{ property.details.woz_year }})</span>
                        </div>
                    </li>
                </ul>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { fetchUrl } from '@/services/ppApi';
    import WidgetSection from '@/components/sections/WidgetSection';

    export default {
        name: "ProspectSameAddress",
        components: {
            WidgetSection
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {
            hide() {
                return !this.property || (!this.loading && (!this.property.details || !!Object.keys(this.property.details).length < 1));
            },
            property() {
                return useProspectStore().property;
            },
        },
        methods: {
            async fetchProperty() {
                if (!this.property || (this.property.api_urls && this.property.api_urls.length < 1)) {
                    return;
                }
                this.loading = true;

                try {
                    const response = await fetchUrl(this.property.api_urls[0]);
                    this.property.details = response.data.property;
                } catch {
                    this.property.details = null;
                } finally {
                    this.loading = false;
                }
            }
        },
        mounted() {
            this.fetchProperty();
        }
    }
</script>