<template>
    <ToggleSimple>
        <template #toggle>
            <div class="left">
                <div v-if="$te(`fields.${name}.label`)" class="toggle-text">
                    {{ $t(`fields.${name}.label`) }}
                    <span v-if="count.selected && count.selected > 0">{{ count.selected }}</span>
                </div>
            </div>
            <div class="right">
                <div v-if="isActive" class="reset" @click="resetSection">
                    <div class="icon-reset-pos"></div>
                </div>
                <div class="toggle-icon"></div>
            </div>
        </template>

        <template #content>
            <CustomCheckbox
                :modelValue="modelValue"
                :inputKey="name"
                @update:modelValue="onChanged"
                :options="options"
                :cssClass="cssClass"
                :showLabel="false"
                ref="optionsSocials"
            />
        </template>
    </ToggleSimple>
</template>

<script>
    import { socialMap } from '@/utils/maps/socialMap';
    import CustomCheckbox from '@/components/elements/inputs/CustomCheckbox';
    import ToggleSimple from '@/components/elements/misc/ToggleSimple';

    export default {
        name: "OptionsSocials",
        components: {
            ToggleSimple,
            CustomCheckbox
        },
        props: {
            modelValue: {
                type: Array,
                default: () => []
            },
            cssClass: {
                type: String,
                default: 'custom-checkbox-type-5'
            }
        },
        data() {
            return {
                name: 'social_exists',
            };
        },
        computed: {
            isActive() {
                return this.modelValue.length > 0;
            },
            options() {
                return socialMap.map(option => ({
                    noTranslate: true,
                    text: option.text,
                    value: option.value,
                    icon: option.icon,
                    color: option.color
                }));
            },
            count() {
                return {
                    total: this.options.length,
                    selected: this.modelValue.length,
                    not_selected: this.options.length - this.modelValue.length
                }
            }
        },
        methods: {
            resetSection(event = null) {
                if (event) {
                    event.stopPropagation()
                }
                this.$emit('update:modelValue', []);
            },
            onChanged(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        }
    }
</script>