import { defineStore } from "pinia";
import { fetchAccounts } from "@/services/ppApi";

export const useAccountsStore = defineStore("accounts", {
    state: () => {
        const defaultOptions = {
            search: '',
            sort: '',
            status: ''
        };
        const defaultPagination = {
            limit: '10',
            page: 1
        };
        
        return {
            accounts: [],
            updatingOptions: false,
            loading: false,
            error: null,
            found: 0,
            total: 0,
            defaultOptions: defaultOptions,
            options: { ...defaultOptions },
            pagination: { ...defaultPagination }
        }
    },
    getters: {
        hasDefaultOptions(state) {
            return JSON.stringify(state.options) === JSON.stringify(state.defaultOptions);
        }
    },
    actions: {
        async loadAccounts() {
            this.loading = true;
            this.error = null;
            try {
                const response = await fetchAccounts({
                    text: this.options.search,
                    sort_by: this.options.sort.split('-')[0] || '',
                    sort_order: this.options.sort.split('-')[1] || '',
                    status: this.options.status,
                    limit: this.pagination.limit,
                    page: this.pagination.page
                });
                this.accounts = response.data.accounts.map(account => ({
                    account_id: account.account_id,
                    url: account.url,
                    domain: account.domain,
                    name: account.name,
                    role: account.role,
                    status: account.status
                }));
                this.found = response.data.found;
                this.total = response.data.total || this.total;
            } catch (error) {
                this.accounts = [];
                this.found = 0;
                this.total = 0;
                this.error = error;
            } finally {
                this.loading = false;
                this.updatingOptions = false;
            }
        },
        updateOptions(newOptions) {
            this.options = { ...this.options, ...newOptions };
            this.pagination.page = 1;
            this.loadAccounts();
        },
        setUpdatingOptions(updating) {
            this.updatingOptions = updating;
        },
        updatePagination(newPagination) {
            this.pagination = { ...this.pagination, ...newPagination };
            this.loadAccounts();
        },
        resetOptions() {
            this.options = { ...this.defaultOptions };
            this.pagination.page = 1;
            this.loadAccounts();
        }
    }
});
