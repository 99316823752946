<template>
    <div class="contact-image">
        <img v-if="imgUrl && imageExists" :src="imgUrl" @error="onError" />
        <div v-else class="contact-letter">{{ letters }}</div>
    </div>
</template>

<script>
export default {
  name: 'ContactImage',
  props: {
    imgUrl: {
      type: String,
      required: false,
      default: ''
    },
    name_first: {
      type: String,
      required: false,
      default: ''
    },
    name_last: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      imageExists: true
    };
  },
  computed: {
    letters() {
        if(this.name_first && this.name_first != '' && this.name_last && this.name_last != '') {
            return this.name_first.charAt(0).toUpperCase() + this.name_last.charAt(0).toUpperCase();
        } else if(this.name_first && this.name_first != '') {
            return this.name_first.charAt(0).toUpperCase();
        } else if(this.name_last && this.name_last != '') {
            return this.name_last.charAt(0).toUpperCase();
        } else {
            return "-";
        }
    }
  },
  methods: {
    onError() {
      this.imageExists = false;
    }
  }
};
</script>