import { defineStore } from 'pinia';

export const useToastStore = defineStore('toast', {
  state: () => ({
    toasts: []
  }),
  actions: {
    addToast(message, type = 'success', path = null, duration = 2500) {
      const toast = { message, type, path, id: Math.random().toString(36).substr(2, 9) };
      this.toasts.push(toast);
      setTimeout(() => {
        this.removeToast(toast.id);
      }, duration);
    },
    removeToast(id) {
      this.toasts = this.toasts.filter(toast => toast.id !== id);
    }
  }
});
