<template>
    <div class="form-type-0">
        <form @submit.prevent="createUser" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="email"
                inputKey="new_user_email"
                type="email"
                ref="email"
                autocomplete="email"
                required
            />
            <CustomSelect
                v-model="role"
                inputKey="user_role"
                :options="roleOptions"
                ref="role"
                required
            />
                   
            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.create_user.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.create_user.error') }}</p>
        </form>
    </div>
</template>

<script>
    import { useUsersStore } from '@/stores/users';
    import formMixin from '@/mixins/formMixin';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CustomSelect from '@/components/elements/inputs/CustomSelect';

    export default {
        mixins: [formMixin],
        props: {
            account_id: {
                type: String,
                default: null
            }
        },
        emits: ['form_submission_successful'],
        components: {
            CustomInput,
            CustomSelect
        },
        data() {
            return {
                email: '',
                role: 20
            };
        },
        computed: {
            usersStore() {
                return useUsersStore();
            },
            roleOptions() {
                const options = [
                    { value: 10, text: 'fields.user_role.options.10' },
                    { value: 20, text: 'fields.user_role.options.20' },
                ];
                const adminOptions = [
                    { value: 5, text: 'fields.new_user_role.options.5' },
                    { value: 9, text: 'fields.new_user_role.options.9' },
                ];
                return this.account_id ? options : adminOptions;
            }
        },
        methods: {
            async createUser() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;
                    let newUser = {
                        email: this.email,
                        role: this.role,
                    };

                    if(this.account_id) {
                        newUser.account_id = this.account_id;
                    }

                    const success = await this.usersStore.createUser(newUser);
                    this.loading = false;
                    
                    if (success) {
                        form.reset();
                        this.formError = false;
                        this.$emit('form_submission_successful');
                    } else {
                        this.formError = true;
                    }
                } else {
                    this.validateAllFields();
                }
            }
        }
    }
</script>