<template>
    <OptionsPanel ref="optionsPanel" cssClass="options-full-width">
        <template #options-results v-if="found || found === 0">
            {{ 
                total === 1 ?
                    $t('views.accounts.results_count.s', { found: found }) :
                    $t('views.accounts.results_count.p', { found: found })
            }}
        </template>
        
        <template #options-default>
            <div class="form-type-1">                
                <CustomInput
                    v-model="options.search"
                    inputKey="accounts_search"
                    @update:modelValue="onChangedDebounced"
                />
                <CustomSelect
                    v-model="options.sort"
                    inputKey="accounts_sort"
                    @update:modelValue="onChanged"
                    :options="sortOptions"
                />
                <CustomSelect
                    v-model="options.status"
                    inputKey="accounts_filter_status"
                    @update:modelValue="onChanged"
                    :options="filterStatusOptions"
                />
            </div>
        </template>

        <template #actions>
            <div :class="hasDefaultOptions ? 'btn-disabled' : 'btn-6'" @click="onReset()">
                <div class="icon-reset-neg"></div>
                <span>{{ $t('common.reset_1') }}</span>
            </div>
        </template>
    </OptionsPanel>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { mapState, mapActions, mapGetters } from 'pinia';
    import { useAccountsStore } from '@/stores/accounts';
    import { useScreenWidthStore } from '@/stores/utils/screenWidth';
    import OptionsPanel from '@/components/panels/options/OptionsPanel';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CustomSelect from '@/components/elements/inputs/CustomSelect';

    export default {
        name: "AccountsOptions",
        components: {
            OptionsPanel,
            CustomInput,
            CustomSelect
        },
        data() {
            return {
                sortOptions: [
                    { value: 'name-asc', text: 'fields.accounts_sort.option_name_asc' },
                    { value: 'name-desc', text: 'fields.accounts_sort.option_name_desc' },
                ],
                filterStatusOptions: [
                    { value: '', text: 'fields.accounts_filter_status.option_all' },
                    { value: '1', text: 'fields.accounts_filter_status.option_1' },
                    { value: '-1', text: 'fields.accounts_filter_status.option_-1' },
                ]
            }
        },
        computed: {
            ...mapState(useAccountsStore, ['options', 'found']),
            ...mapGetters(useAccountsStore, ['hasDefaultOptions']),
            screenWidthStore() {
                return useScreenWidthStore();
            },
            hasLargeScreen() {
                return this.screenWidthStore.width >= 992;
            },
        },
        methods: {
            ...mapActions(useAccountsStore, ['updateOptions', 'setUpdatingOptions', 'resetOptions']),
            onChanged() {
                this.updateOptions(this.options);
            },
            onChangedDebounced(value) {
                this.setUpdatingOptions(true);
                this.options.search = value;
                this.updateDebounced();
            },
            updateDebounced: debounce(function() {
                this.updateOptions(this.options);
            }, 500),
            onReset() {
                this.resetOptions();
                this.$refs.optionsPanel.toggleOptions();
            }
        }
    }
</script>