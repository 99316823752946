<template>
  <div :class="cssClass">
    <div class="popup-container">
      <div v-if="showCloseIcon" class="close-icon" @click="closePopup">
          <div class="icon-close-pos"></div>
      </div>
      <div class="content">
        <h3>{{ translationPlaceholders && translationPlaceholders.title ? $t(title, translationPlaceholders.title ) : $t(title) }}</h3>
        <p>{{ translationPlaceholders && translationPlaceholders.description ? $t(description, translationPlaceholders.description ) : $t(description) }}</p>
        <component :is="formComponent" v-bind="formProps" @form_submission_successful="formSubmissionSuccessful" />
      </div>
    </div>
  </div>
</template>

<script>
import { usePopupStore } from '@/stores/popup';
import { mapActions } from 'pinia';

export default {
    props: {
        title: String,
        description: String,
        formComponent: Object,
        showCloseIcon: {
            type: Boolean,
            default: true
        },
        cssClass: {
            type: String,
            default: 'popup-type-1'
        },
        formProps: {
            type: Object,
            default: () => ({})
        },
        translationPlaceholders: {
            type: Object,
            default: () => ({})
        },
        onFormSuccess: {
            type: Function,
            required: false
        }
    },
    methods: {
        ...mapActions(usePopupStore, ['closePopup']),
        formSubmissionSuccessful() {
            this.closePopup();

            if( this.onFormSuccess ) {
                this.onFormSuccess();
            }
        }
    }
};
</script>
