<template>
    <div class="form-type-0">
        <form @submit.prevent="editContact" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="name_first"
                inputKey="contact_name_first"
                type="text"
                ref="name"
            />
            <CustomInput
                v-model="name_last"
                inputKey="contact_name_last"
                type="text"
                ref="name"
                required
            />
            <CustomInput
                v-model="job_title"
                inputKey="contact_job_title"
                type="text"
                ref="name"
            />
            <CustomInput
                v-model="email"
                inputKey="contact_email"
                type="email"
                ref="name"
            />
            <CustomInput
                v-model="phone"
                inputKey="contact_phone"
                type="text"
                ref="name"
            />
            <CustomInput
                v-model="linkedin_link"
                inputKey="contact_linkedin_link"
                type="text"
                ref="name"
            />
            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.edit_contact.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.edit_contact.error') }}</p>
        </form>
    </div>
</template>

<script>
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import formMixin from '@/mixins/formMixin';
    import { useProspectStore } from '@/stores/prospect';

    export default {
        name: "EditContactForm",
        mixins: [formMixin],
        props: {
            contact: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                contact_id: this.contact.contact_id,
                name_first: this.contact.name_first || '',
                name_last: this.contact.name_last || '',
                job_title: this.contact.job_title || '',
                phone: this.contact.phone || '',
                email: this.contact.email || '',
                linkedin_link: this.contact.linkedin_link || ''
            }
        },
        components: {
            CustomInput
        },
        methods: {
            async editContact() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;
                    const success = await useProspectStore().editContact({
                        contact_id: this.contact_id,
                        name_first: this.name_first,
                        name_last: this.name_last,
                        job_title: this.job_title,
                        phone: this.phone,
                        linkedin_link: this.linkedin_link,
                        email: this.email
                    });
                    this.loading = false;

                    if (success) {
                        this.formSuccess = true;
                        this.formError = false;
                        this.$emit('form_submission_successful');
                    } else {
                        this.formSuccess = false;
                        this.formError = true;
                    }

                } else {
                    this.validateAllFields();
                }
            }
        }
    }
</script>
