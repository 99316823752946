<template>
    <div class="error-section-type-1">
        <div class="error-message">{{ $te(`errors.${error.message}`) ? $t(`errors.${error.message}`) : $t('errors.loading') }}</div>
    </div>
</template>

<script>
export default {
    name: "ErrorSection",
    props: {
        error: {
            type: Object,
            required: true
        }
    }
}
</script>