import router from '@/router';
import { defineStore } from 'pinia';
import { fetchSuspects } from '@/services/ppApi';
import { useAccountStore } from '@/stores/account';
import { useUserStore } from '@/stores/user';
import { useProspectStore } from '@/stores/prospect';

export const useSuspectStore = defineStore('suspect', {
    state: () => {
        const accountStore = useAccountStore();
        const accountId = accountStore && accountStore.account && accountStore.account.account_id ? accountStore.account.account_id : null;
        const userStore = useUserStore();
        const userSuspectSettings = userStore.getSuspectSettings(accountId || null);

        const defaultOptions = {
            search: '',
            socials: [],
            location: {
                province: [],
                city: '',
                radius: null,
            },
            apps: [],
            employees: '',
            founded: ''
        };

        return {
            currentParams: userSuspectSettings && userSuspectSettings.currentParams ? userSuspectSettings.currentParams : {},
            nextParams: userSuspectSettings && userSuspectSettings.nextParams ? userSuspectSettings.nextParams : {},
            history: [],
            loading: false,
            error: null,
            defaultOptions: defaultOptions,
            options: userSuspectSettings && userSuspectSettings.options ? { ...defaultOptions, ...userSuspectSettings.options } : { ...defaultOptions },
            optionsChanged: false
        }
    },
    getters: {
        hasDefaultOptions(state) {
            return JSON.stringify(state.options) === JSON.stringify(state.defaultOptions);
        }
    },
    actions: {
        async fetchSuspect() {
            this.loading = true;

            try {
                const params = this.generateQueryParams();
                const response = await fetchSuspects({ ...params, add_details: '*' });

                if(response.data.found && response.data.found > 0) {
                    useProspectStore().setProspect(response.data.companies[0]);

                    const url = new URL(response.data.next);
                    this.currentParams = params;
                    this.nextParams = Object.fromEntries(url.searchParams.entries());
                    this.saveUserSuspectSettings();
                } else {
                    useProspectStore().resetProspect();
                    this.saveUserSuspectSettings(true);
                    router.push({ name: 'prospects' });
                }
            } catch {
                // Handle inactive subscriptions etc. here.
                // this.saveUserSuspectSettings(true);
                // router.push({ name: 'prospects' });
            } finally {
                this.loading = false;
                this.optionsChanged = false;
            }
        },
        async qualifySuspect(label) {
            const prospectStore = useProspectStore();
            if(label !== -1 || (label === -1 && prospectStore.prospect.label !== 0 && prospectStore.prospect.label !== 1)) {
                await prospectStore.updateLabel(label);
            }
            this.history.push(prospectStore.prospect.id);
            this.fetchSuspect();
        },
        generateQueryParams() {
            const accountStore = useAccountStore();
            const prospectStore = useProspectStore();

            if(!prospectStore.prospect && Object.keys(this.currentParams).length !== 0) {
                return {
                    account_id: accountStore.account.account_id,
                    ...this.currentParams
                };
            } else if(Object.keys(this.nextParams).length === 0) {
                var location = {};

                if(this.options.location.province.length > 0) {
                    location = { province: this.options.location.province.join(',') }
                } else if (this.options.location.city !== '' && this.options.location.radius !== null && this.options.location.radius !== '0') {
                    location = {
                        location: this.options.location.city,
                        km: this.options.location.radius
                    }
                } else if (this.options.location.city !== '') {
                    location = { city: this.options.location.city }
                }

                return {
                    account_id: accountStore.account.account_id,
                    ...(this.options.search && {text: this.options.search}),
                    ...(this.options.employees && {employees: this.options.employees}),
                    ...(this.options.founded && {founded: this.options.founded}),
                    ...(this.options.socials && this.options.socials.length > 0 && {social_exists: this.options.socials.join(',')}),
                    ...(this.options.apps && this.options.apps.length > 0 && {apps: this.options.apps.map(obj => obj.value).join(',')}),
                    ...location
                };
            } else {
                return {
                    account_id: accountStore.account.account_id,
                    ...this.nextParams
                };
            }
        },
        async previousSuspect() {
            this.loading = true;
            
            try {
                const prospectStore = useProspectStore();
                if(this.history && this.history.length > 0) {
                    const success = await prospectStore.fetchProspect(this.history[this.history.length - 1]);

                    if(success) {
                        this.history.pop();
                    } else {
                        this.history = [];
                    }
                }
            } catch {
                this.history = [];
            } finally {
                this.loading = false;
            }
        },
        resetSuspect() {
            this.$reset();
        },
        updateOptions(newOptions) {
            this.options = { ...this.options, ...newOptions };
            this.nextParams = {};
            this.currentParams = {};
            this.optionsChanged = true;
        },
        resetOptions() {
            this.options = { ...this.defaultOptions };
            this.fetchSuspect();
        },
        saveUserSuspectSettings(deleteSettings = false) {
            const userStore = useUserStore();
            const accountStore = useAccountStore();

            userStore.saveSuspectSettings(
                accountStore.account.account_id,
                deleteSettings ? null : {
                    options: this.options,
                    nextParams: this.nextParams,
                    currentParams: this.currentParams
                }
            );
        }
    }
});