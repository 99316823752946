<template>
    <div v-if="contacts.length > 0" class="contacts contacts-type-1">
        <ContactImage 
            v-for="contact in visibleContacts"
            :key="contact.contact_id"
            :imgUrl="contact.img"
            :name_first="contact.name_first"
            :name_last="contact.name_last"
        />

        <div v-if="numberOfContacts > 3" class="indicator">
            <div><span>+</span>{{ numberOfContacts - visibleContacts.length }}</div>
        </div>
    </div>
</template>

<script>
import ContactImage from "@/components/elements/misc/ContactImage";

export default {
    name: "ContactsMini",
    props: {
        contacts: {
            type: Array,
            default: () => []
        },
        numberOfContacts: {
            type: Number,
            required: false
        }
    },
    components: {
        ContactImage
    },
    computed: {
        visibleContacts() {
            if(this.numberOfContacts && this.numberOfContacts > 0) {
                return this.contacts.slice(0, 3);
            } else {
                return this.contacts;
            }
        },
    }
}
</script>