<template>
    <button @click="createUserPopup" class="btn-s-add btn-1">
        <span>{{$t('components.create_user.button_create')}}</span>
        <div class="icon-plus-neg"></div>
    </button>
</template>

<script>
    import i18n from "@/plugins/i18n";
    import { usePopupStore } from '@/stores/popup';
    import { useAccountStore } from '@/stores/account';
    import { useToastStore } from '@/stores/toast';
    import UserCreateForm from '@/components/forms/UserCreateForm.vue';
    import { markRaw } from 'vue';
    import { scrollToTop } from '@/utils/scroll';

    export default {
        name: "UserCreate",
        computed: {
            accountStore() {
                return useAccountStore();
            },
            popupStore() {
                return usePopupStore();
            },
            toastStore() {
                return useToastStore();
            }
        },
        methods: {
            createUserPopup() {
                this.popupStore.showPopup('form', {
                    title: 'popups.create_user.title',
                    description: 'popups.create_user.description',
                    formComponent: markRaw(UserCreateForm),
                    translationPlaceholders: {
                        description: {
                            accountName: this.accountStore.account && this.accountStore.account.name ? this.accountStore.account.name : this.accountStore.account && this.accountStore.account.domain ? this.accountStore.account.domain : null
                        }
                    },
                    formProps: {
                        account_id: this.accountStore.account && this.accountStore.account.account_id ? this.accountStore.account.account_id : null
                    },
                    onFormSuccess: () => {
                        this.toastStore.addToast(i18n.global.t('toasts.success.user_created'), 'success');
                        scrollToTop();
                    }
                });
            },
        },
    }
</script>