<template>
    <div class="custom-select">
        <label v-if="$te(label)" :for="uniqueId" :class="['input-label', { required: required }]">{{ $t(label) }}</label>
        <div class="custom-select-wrap">
            <select
            :value="modelValue"
            :id="uniqueId"
            :ref="inputKey"
            :name="inputKey"
            :required="required"
            @change="onInput"
            >
                <option value="" disabled>{{ $te(placeholder) ? $t(placeholder) : $t('fields.default.select.placeholder') }}</option>
                <option v-for="option in options" :key="option.value" :value="option.value">
                    {{ option.noTranslate ? option.text : $t(option.text) }}
                </option>
            </select>
        </div>
        <p v-if="inputError" class="input-error">{{ $te(error) ? $t(error) : $t('fields.default.select.error') }}</p>
    </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
    props: {
        inputKey: String,
        modelValue: [String, Number],
        required: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            inputError: false,
            label: `fields.${this.inputKey}.label`,
            placeholder: `fields.${this.inputKey}.placeholder`,
            error: `fields.${this.inputKey}.error`,
            uniqueId: `${this.inputKey}-${Math.random().toString(36).substr(2, 9)}`
        };
    },
    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.value);
            nextTick(() => {
                this.validateInput(event.target);
            });
        },
        validateInput(input) {
            if (!input.checkValidity()) {
                this.inputError = true;
                input.classList.add('is-invalid');
                input.classList.remove('is-valid');
                return false;
            } else {
                this.inputError = false;
                input.classList.add('is-valid');
                input.classList.remove('is-invalid');
                return true;
            }
        },
        triggerValidation() {
            const input = this.$refs[this.inputKey];
            if (input) {
                return this.validateInput(input);
            } else {
                // Nothing to see here.
                return true;
            }
        }
    },
};
</script>
