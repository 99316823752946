<template>
    <WidgetSection :loading="loading" v-if="!hide" cssClass="slider-type-1">
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_reviews.name')}}
            </div>
        </template>

        <template v-slot:section-actions>
            <div class="swiper-navigation">
                <div :class="`swiper-button-prev-${uniqueId}`"></div>
                <div :class="`swiper-button-next-${uniqueId}`"></div>
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-slider">
                <swiper-container ref="swiper" init="false">
                    <swiper-slide v-for="(review, index) in reviews" :key="index">
                        <div class="slide-container">
                            <StarsReview v-if="review.rating" :score="review.rating" :fullWidth="true" />
                            <div class="review-content">
                                <div>
                                    {{ review.text }}
                                </div>
                            </div>
                            <div class="bar">
                                <div class="left">
                                    <ContactImage 
                                        :imgUrl="review.user_img"
                                        :name_first="review.name"
                                    />
                                </div>
                                <div class="right">
                                    <div class="name" v-if="review.name">{{ review.name }}</div>
                                    <div class="source" v-if="review.source">{{$t('common.source')}}: {{ review.source }}</div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import WidgetSection from '@/components/sections/WidgetSection';
    import ContactImage from "@/components/elements/misc/ContactImage";
    import StarsReview from '@/components/elements/misc/StarsReview';

    export default {
        name: "ProspectApps",
        components: {
            WidgetSection,
            ContactImage,
            StarsReview
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {
            reviews() {
                return useProspectStore().reviewsDetails;
            },
            hide() {
                return !this.loading && (!this.reviews || this.reviews.length < 1);
            },
            uniqueId() {
                return Math.random().toString(36).substr(2, 9);
            },
        },
        methods: {
            initSwiper() {
                if(!this.$refs.swiper) { return; }
                
                const swiperEl = this.$refs.swiper;
                const swiperParams = {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    breakpoints: {
                        769: {
                            slidesPerView: 2,
                            slidesPerGroup: 2
                        },
                        992: {
                            slidesPerView: 3,
                            slidesPerGroup: 3
                        }
                    },
                    navigation: {
                        nextEl: `.swiper-button-next-${this.uniqueId}`,
                        prevEl: `.swiper-button-prev-${this.uniqueId}`
                    }
                };
                Object.assign(swiperEl, swiperParams);
                swiperEl.initialize();
            },
        },
        mounted() {
            this.initSwiper();
        }
    }
</script>