<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <div class="container-fluid">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="content">
                            <pre>{{ account }}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AccountViewBase>
</template>

<script>
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';

    export default {
        name: 'HomeView',
        components: {
            AccountViewBase
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.home.seo_title',
            };
        }
    }
</script>
