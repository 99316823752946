<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { defineComponent } from 'vue';

Chart.register(...registerables);

export default defineComponent({
  name: 'GraphGauge',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    value(newVal) {
      if (this.chart) {
        this.chart.data.datasets[0].data = [newVal, 100 - newVal];
        this.chart.data.datasets[0].backgroundColor = [this.getChartColor(), 'transparent'];
        this.chart.update();
      }
    }
  },
  methods: {
    createChart() {
      const ctx = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [this.value, 100 - this.value],
            backgroundColor: [this.getChartColor(), 'rgba(255, 255, 255, 0.36)'],
            borderWidth: 0
          }]
        },
        options: {
          responsive: true,
          cutout: '80%',
          plugins: {
            tooltip: {
              enabled: false
            }
          }
        },
        plugins: [{
          id: 'centerText',
          afterDraw: (chart) => {
            const { width, height, ctx } = chart;
            ctx.save();
            const fontSize = (height / 114).toFixed(2);
            ctx.font = `${fontSize}em "Plus Jakarta Sans", sans-serif`;
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#000'; // Set text color to black
            const text = `${this.value}%`;
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            const textY = height / 2;
            ctx.fillText(text, textX, textY);
            ctx.restore();
          }
        }]
      });
    },
    getChartColor() {
      if (this.value < 50) return '#CA2E55';
      if (this.value < 80) return '#F7B538';
      return '#4AAD52';
    }
  }
});
</script>

<style scoped>
    canvas {
    width: 100% !important;
    height: 100% !important;
    }
</style>