<template>
    <SettingsSection cssClass="account-api-settings">
        <template #title>
            <h2>{{ $t('views.account_settings.account_api_settings_title') }}</h2>
        </template>

        <template #content>
            <ApiKeyManager :apiKeyExists="!!account.api_key_exists" />
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/account/settings/Section';
    import ApiKeyManager from '@/components/elements/misc/ApiKeyManager';
    
    export default {
        name: 'AccountApiSettings',
        components: {
            SettingsSection,
            ApiKeyManager
        },
        props: {
            account: {
                type: Object,
                required: true
            }
        }
    }
</script>