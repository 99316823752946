export const provinceMap = [
    {
        text: "Drenthe",
        value: "Drenthe",
        icon: "drenthe"
    },
    {
        text:"Flevoland",
        value: "Flevoland",
        icon: "flevoland"
    },
    {
        text:"Friesland",
        value: "Friesland",
        icon: "friesland"
    },
    {
        text:"Gelderland",
        value: "Gelderland",
        icon: "gelderland"
    },
    {
        text:"Groningen",
        value: "Groningen",
        icon: "groningen"
    },
    {
        text:"Limburg",
        value: "Limburg",
        icon: "limburg"
    },
    {
        text:"Noord-Brabant",
        value: "Noord-Brabant",
        icon: "noord-brabant"
    },
    {
        text:"Noord-Holland",
        value: "Noord-Holland",
        icon: "noord-holland"
    },
    {
        text:"Overijssel",
        value: "Overijssel",
        icon: "overijssel"
    },
    {
        text:"Utrecht",
        value: "Utrecht",
        icon: "utrecht"
    },
    {
        text:"Zeeland",
        value: "Zeeland",
        icon: "zeeland"
    },
    {
        text:"Zuid-Holland",
        value: "Zuid-Holland",
        icon: "zuid-holland"
    }
];