<template>
  <div :class="[includeText ? 'language-switch-type-2' : 'language-switch' ]">
    <button
      v-for="lang in availableLanguages"
      :key="lang.code"
      :class="['lang-option', `lang-${lang.code}`, { 'lang-current': lang.code === currentLocale }]"
      @click="switchLanguage(lang.code)"
    >
        <div :class="`icon-${lang.icon}`"></div>
        <div v-if="includeText" class="lang-text">{{ lang.label }}</div>
    </button>
  </div>
</template>

<script>
    import { useLanguageStore } from '@/stores/utils/language';

    export default {
        name: 'LanguageSwitch',
        props: {
            includeText: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            languageStore() {
                return useLanguageStore();
            },
            currentLocale() {
                return this.languageStore.locale;
            },
            availableLanguages() {
                return this.languageStore.availableLanguages;
            }
        },
        methods: {
            switchLanguage(locale) {
                this.languageStore.setLocale(locale);
            }
        }
    };
</script>
