<template>
    <div class="container-fluid content-type-1">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-xl-6 col-xl-offset-3">
                    <div class="content glass">
                        <LanguageSwitch />
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LanguageSwitch from '@/components/elements/misc/LanguageSwitch';

    export default {
            name: 'AuthSection',
            components: {
                LanguageSwitch
            }
    }
</script>