<template>
    <WidgetSection :loading="loading" loadingText="common.loading_1" v-if="!hide">
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_family.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="table-container">
                    <table class="table-type-1 companies">
                        <thead>
                            <tr class="table-header">
                                <td></td>
                            <td>{{ $t('common.company_name') }}</td>
                                <td>{{ $t('common.coc') }}</td>
                                <td>{{ $t('common.phone') }}</td>
                                <td>{{ $t('common.website') }}</td>
                                <td>{{ $t('common.address') }}</td>
                                <td>{{ $t('common.postcode') }}</td>
                                <td>{{ $t('common.city') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="company" v-for="company in family.details" :key="company.id">
                                <td>
                                    <CompanyLogo 
                                        :domain="company.domain ? company.domain : null"
                                        :company_name="company.name ? company.name : company.domain ? company.domain : null"
                                        class="company-image"
                                    />
                                </td>
                                <td class="company-name">{{ company.name }}</td>
                                <td>{{ company.id }}</td>
                                <td><a v-if="company.phone_international" :href="'tel:' + company.phone_international.replace('+', '00').replaceAll(' ', '')" target="_blank" rel="noreferrer external">{{ company.phone_international }}</a></td>
                                <td class="website"><a v-if="company.url" :href="company.url" target="_blank" rel="noreferrer external">{{ company.url }}</a></td>
                                <td class="address">{{ company.address }}</td>
                                <td>{{ company.postcode }}</td>
                                <td>{{ company.city }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { fetchUrl } from '@/services/ppApi';
    import WidgetSection from '@/components/sections/WidgetSection';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';

    export default {
        name: "ProspectFamily",
        components: {
            WidgetSection,
            CompanyLogo
        },
        data() {
            return {
                loading: false,
                loadingEmailValidation: false
            }
        },
        computed: {
            hide() {
                return !this.family || (!this.loading && (!this.family.details || this.family.details.length < 1));
            },
            family() {
                return useProspectStore().family;
            }
        },
        methods: {
            async fetchFamily() {
                if (!this.family || (this.family.api_urls && this.family.api_urls.length < 1)) {
                    return;
                }
                this.loading = true;

                try {
                    const response = await fetchUrl(this.family.api_urls[0]);
                    this.family.details = response.data.companies;
                } catch {
                    this.family.details = null;
                } finally {
                    this.loading = false;
                }
            }
        },
        mounted() {
            this.fetchFamily();
        }
    }
</script>