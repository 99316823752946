import { defineStore } from 'pinia';

export const useMainNavStore = defineStore('mainNav', {
  state: () => ({
    showNav: true
  }),
  actions: {
    toggleNav(show = null) {
      this.showNav =  show !== null ? show : !this.showNav;
    }
  }
});
