<template>
    <div class="tags-type-1">{{allTags.join(', ')}}</div>
</template>

<script>
export default {
    name: "TagsMini",
    props: {
        tags: {
            type: String,
            default: ''
        }
    },
    computed: {
        allTags() {
            return this.tags ? this.tags.split('|') : [];
        }
    }
}
</script>