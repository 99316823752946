import router from '@/router';
import { defineStore } from 'pinia';
import { fetchAccount, updateAccount, fetchAccountTags, fetchAccountApiKey, deleteAccountApiKey } from "@/services/ppApi";
import { useSuspectStore } from '@/stores/suspect';
import { useProspectStore } from '@/stores/prospect';
import { useProspectsStore } from '@/stores/prospects';
import { useUsersStore } from '@/stores/users';

export const useAccountStore = defineStore('account', {
    state: () => {
        const defaultTags = [
            { value: 'Suspect', text: 'Suspect', count: 1 },
            { value: 'Prospect', text: 'Prospect', count: 1 },
            { value: 'Opportunity', text: 'Opportunity', count: 1 },
            { value: 'Customer', text: 'Customer', count: 1 },
            { value: 'Partner', text: 'Partner', count: 1 },
            { value: 'Rival', text: 'Rival', count: 1 }
        ];

        return {
            account: JSON.parse(localStorage.getItem('pp_account')) || null,
            error: null,
            loading: false,
            defaultTags: defaultTags,
            tags: defaultTags,
        }
    },
    getters: {
        hasDefaultTags: (state) => JSON.stringify(state.tags) === JSON.stringify(state.defaultTags),
        hasAccountId: (state) => state.account && typeof state.account.account_id === 'string' && state.account.account_id.trim().length > 0,
        hasFullAccount: (state) => state.account && state.account.account_id && state.account.name && state.account.url
    },
    actions: {
        setAccount(account) {
            if (account) {
                if (account.account_id && account.account_id === this.account?.account_id) {
                    this.account = { ...this.account, ...account };
                } else {
                    this.account = account;
                }
                localStorage.setItem('pp_account', JSON.stringify(this.account));
            } else {
                this.account = null;
                this.error = null;
                this.loading = false;
                localStorage.removeItem('pp_account');
            }
        },
        resetAccount() {
            this.tags = this.defaultTags; // Somehow this.$reset below doesn't reset this automatically..
            this.setAccount(null);
            this.$reset;

            // Clear stores related to the account
            useUsersStore().$reset();
            useSuspectStore().$reset();
            useProspectStore().$reset();
            useProspectsStore().$reset();
        },
        resetState() {
            this.error = null;
            this.loading = false;
        },
        changeAccount(account_id) {
            this.resetAccount();
            this.setAccount({ account_id })
        },
        setApiKeyStatus(status) {
            this.account.api_key_exists = status;
            this.setAccount(this.account);
        },
        setAccountTags(tags) {
            tags = tags.filter(tag => tag.count && tag.count > 0);
            if(tags.length > 0) {
                this.tags = tags;
            } else {
                this.tags = this.defaultTags;
            }
        },
        async fetchAccount() {
            if (this.hasAccountId) {
                this.loading = true;
                try {
                    const response = await fetchAccount(this.account.account_id);

                    if(response.data.found && response.data.found === 1) {
                        const account = response.data.accounts[0];
                        this.setAccount(account);
                    } else {
                        router.push({ name: 'accounts'} );
                    }
                } catch (error) {
                    this.error = error;
                } finally {
                    this.loading = false;
                }
            } else {
                return false;
            }
        },
        async getAccount() {
            if (!this.hasAccountId) {
            router.push({ name: 'accounts'} );
            }
            if (!this.hasFullAccount) {
                await this.fetchAccount();
            }
            return this.hasFullAccount ? this.account : false;
        },
        async updateAccount(updatedAccount) {
            if (this.hasAccountId) {
                updatedAccount = { account_id: this.account.account_id, ...updatedAccount };
            }

            try {
                const response = await updateAccount(updatedAccount);
                this.setAccount(response.data.accounts[0]);
                return true;
            } catch (error) {
                return false;
            }
        },
        async fetchAccountTags() {
            if (this.hasAccountId) {
                try {
                    const response = await fetchAccountTags(this.account.account_id);

                    if(response.data.found && response.data.found > 0) {
                        var tags = response.data.tags ?
                            Object.keys(response.data.tags).map(key => ({
                                value: key,
                                text: key,
                                count: response.data.tags[key]
                            })) : this.tags;
                        this.setAccountTags(tags);
                    } else {
                        return false;
                    }
                } catch {
                    return false;
                }
            } else {
                return false;
            }
        },
        async getAccountTags() {
            if(this.hasDefaultTags || (this.tags && this.tags.length < 1)) { // Combine this with C(R)UD-labels (i.e. reload tags immediately after)
                await this.fetchAccountTags();
            }
            return this.tags.length > 0 ? this.tags : this.defaultTags;
        },
        async fetchAccountApiKey() {
            if (this.hasAccountId) {
                try {
                    const response = await fetchAccountApiKey(this.account.account_id);
                    return response.data.api_key;
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
        async deleteAccountApiKey() {
            if (this.hasAccountId) {
                try {
                    return await deleteAccountApiKey(this.account.account_id);
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
    }
});