import { defineStore } from 'pinia';
import { fetchUsers, createUser, updateUser, deleteUser } from "@/services/ppApi";

export const useUsersStore = defineStore('users', {
    state: () => ({
        users: [],
        users_found: false,
        error: null,
        loading: false
    }),
    actions: {
        async fetchUsers(account_id = null) {
            this.loading = true;
            this.error = null;

            const params = account_id ? { account_id } : {};

            try {
                const response = await fetchUsers(params);
                this.users = response.data.users;
                this.users_found = response.data.found;
            } catch (error) {
                this.users = [];
                this.users_found = 0;
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        async getUsers(account_id = null) {
            if (!this.users || this.users.length < 1) {
                await this.fetchUsers(account_id);
            }
            return this.users ? this.users : false;
        },
        async createUser(user) {
            try {
                const response = await createUser(user);
                const createdUser = response.data.users[0];
                this.users.unshift(createdUser);
                return true;
            } catch (error) {
                return false;
            }
        },
        async updateUser(user) {
            try {
                const response = await updateUser(user);
                const updatedUser = response.data.users[0];
        
                const index = this.users.findIndex(u => u.uid === updatedUser.uid);
                if (index !== -1) {
                    this.users[index] = { ...this.users[index], ...updatedUser };
                } else {
                    this.users.push(updatedUser);
                }
                return true;
            } catch (error) {
                return false;
            }
        },
        async deleteUser(user) {
            try {
                await deleteUser(user);
                this.users = this.users.filter(existingUser => existingUser.uid !== user.uid);
                return true;
            } catch (error) {
              return false;
            }
        },
    }
});
