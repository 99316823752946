import { defineStore } from 'pinia';

export const useScreenWidthStore = defineStore('screenWidth', {
  state: () => ({
    width: window.innerWidth
  }),
  actions: {
    updateWidth() {
      this.width = window.innerWidth;
    }
  }
});