<template>
    <div class="loading-section-type-1">
        <div class="loading-spinner-type-2"></div>
        <div v-if="showMessage" class="loading-text">{{ $te(message) ? $t(message) : $t('common.loading_1') }}</div>
    </div>
</template>

<script>
export default {
    name: "LoadingSection",
    props: {
        message: {
            type: String,
            required: false
        },
        showMessage: {
            type: Boolean,
            default: true
        }
    }
}
</script>